import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DefaultProfilePic from '../../../assets/DefaultProfilePic.webp';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb.js';
import EditorToolbar, {
  formats,
  modules,
} from '../../../components/EditorToolbar/EditorToolbar.js';
import FileUploadButton from '../../../components/FileUploadButton/FileUploadButton.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { htmlToPlainText, isPastDate } from '../../../core/functions.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import { fetchUserUsage } from '../../../redux/thunks/UserThunks.js';
import styles from './ReviewPage.module.css';

const ReviewPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const [error, setError] = useState('');
  const [selectedProfiles, setSelectedProfiles] = useState(
    location.state?.selectedProfiles || []
  );
  const [selectedProfile, setSelectedProfile] = useState({});
  const [profileData, setProfileData] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs().add(60, 'minute'));
  const fileInputRef = useRef(null);
  const userData = useSelector((state) => state.user.data) || {};
  const limits = useSelector((state) => state.user.limits) || {};
  const usage = useSelector((state) => state.user.usage) || {};
  const { uid = '', displayName = '' } = userData;

  useEffect(() => {
    if (selectedProfiles.length > 0) {
      setSelectedProfile(selectedProfiles[0]);
      const newData = selectedProfiles.reduce((acc, profile) => {
        const rr_id = profile?.rr_id;
        if (rr_id) {
          acc[rr_id] = {
            emailSent: false,
            selectedDraftSubject:
              populateTemplate(location.state?.emailSubjectTemplate, profile) ||
              '',
            selectedDraftBody:
              populateTemplate(location.state?.emailBodyTemplate, profile) ||
              '',
            selectedUploadedFile: location.state?.uploadedFile || null,
          };
        }
        return acc;
      }, {});
      setProfileData((prev) => ({ ...prev, ...newData }));
    }
    // eslint-disable-next-line
  }, [selectedProfiles]);

  const updateProfileData = (key, value) => {
    setProfileData((prev) => ({
      ...prev,
      [selectedProfile.rr_id]: {
        ...prev[selectedProfile.rr_id],
        [key]: value,
      },
    }));
  };

  const populateTemplate = (template, profile) => {
    if (!template) return '';
    const firstName = profile.name.split(' ')[0];
    const lastName = profile.name.split(' ').slice(1).join(' ');
    let populatedTemplate = template.replace(
      /{{\s*Recipient Name\s*}}/g,
      profile.name
    );
    populatedTemplate = populatedTemplate.replace(
      /{{\s*Recipient FirstName\s*}}/g,
      firstName
    );
    populatedTemplate = populatedTemplate.replace(
      /{{\s*Recipient LastName\s*}}/g,
      lastName
    );
    populatedTemplate = populatedTemplate.replace(
      /{{\s*Company Name\s*}}/g,
      profile.current_employer
    );
    const sender = displayName || 'No Sender Name';
    populatedTemplate = populatedTemplate.replace(
      /{{\s*Your Name\s*}}/g,
      sender
    );
    return populatedTemplate;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size <= 2.5 * 1024 * 1024) {
        updateProfileData('selectedUploadedFile', {
          file: selectedFile,
          fileName: selectedFile.name,
        });
        setError('');
      } else {
        updateProfileData('selectedUploadedFile', null);
        setError('File size should not exceed 5MB.');
      }
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveAttachment = () => {
    updateProfileData('selectedUploadedFile', null);
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const checkUserExceedUsageLimits = () => {
    if (usage.monthly_email_credit_usage >= limits.monthly_email_credit_limit) {
      addNotification({
        message:
          'Monthly Email credit limit reached; contact support or upgrade your plan.',
        type: 'error',
      });
      return true;
    }
    if (usage.daily_email_credit_usage >= limits.daily_email_credit_limit) {
      addNotification({
        message:
          'Daily Email credit limit reached; contact support or upgrade your plan.',
        type: 'error',
      });
      return true;
    }
    return false;
  };

  const createDraft = async () => {
    if (checkUserExceedUsageLimits()) return;
    const selectedFile =
      profileData[selectedProfile.rr_id]?.selectedUploadedFile?.file;
    let base64File = null;
    if (selectedFile) {
      base64File = await fileToBase64(selectedFile);
    }
    const jsonPayload = {
      uid,
      rrId: selectedProfile.rr_id,
      recipientName: selectedProfile.name,
      recipientDomain: selectedProfile.current_employer_domain,
      companyName: selectedProfile.current_employer,
      subject: profileData[selectedProfile.rr_id]?.selectedDraftSubject,
      htmlBody: profileData[selectedProfile.rr_id]?.selectedDraftBody,
      plainTextBody: htmlToPlainText(
        profileData[selectedProfile.rr_id]?.selectedDraftBody
      ),
      file: base64File,
      fileName: selectedFile?.name,
    };

    addNotification({
      message: `Finding ${selectedProfile.name}'s email and creating an email draft...`,
      type: 'info',
    });
    const response = await postMessage('create_draft', jsonPayload);
    if (response.status == 200) {
      addNotification({
        message: 'Email draft created successfully.',
        type: 'success',
      });
    } else if (response.body.errorType == 'emailNotFound') {
      addNotification({
        message: 'Recipient email not found; unable to create draft.',
        type: 'error',
      });
      const rr_id = response.rrId;
      setSelectedProfiles((prev) =>
        prev.filter((profile) => profile.rr_id !== rr_id)
      );
      setSelectedProfile(selectedProfiles[0]);
    } else if (response.body.errorType == 'refreshError') {
      addNotification({
        message: 'Your email requires a token refresh; unable to create draft.',
        type: 'error',
      });
    } else {
      addNotification({
        message: 'Server error while drafting email; please try again later.',
        type: 'error',
      });
    }
    dispatch(fetchUserUsage(uid));
  };

  const sendEmail = async () => {
    if (checkUserExceedUsageLimits()) return;
    const selectedFile =
      profileData[selectedProfile.rr_id]?.selectedUploadedFile?.file;
    let base64File = null;
    if (selectedFile) {
      base64File = await fileToBase64(selectedFile);
    }
    updateProfileData('emailSent', true);
    const jsonPayload = {
      uid,
      rrId: selectedProfile.rr_id,
      recipientName: selectedProfile.name,
      recipientDomain: selectedProfile.current_employer_domain,
      companyName: selectedProfile.current_employer,
      subject: profileData[selectedProfile.rr_id]?.selectedDraftSubject,
      htmlBody: profileData[selectedProfile.rr_id]?.selectedDraftBody,
      plainTextBody: htmlToPlainText(
        profileData[selectedProfile.rr_id]?.selectedDraftBody
      ),
      file: base64File,
      fileName: selectedFile?.name,
    };

    addNotification({
      message: `Finding ${selectedProfile.name}'s email and sending email...`,
      type: 'info',
    });
    const response = await postMessage('create_and_send_message', jsonPayload);
    if (response.status == 200) {
      addNotification({
        message: 'Email sent successfully.',
        type: 'success',
      });
    } else if (response.body.errorType == 'emailNotFound') {
      addNotification({
        message: 'Recipient email not found; unable to send email.',
        type: 'error',
      });
      const rr_id = response.rrId;
      setSelectedProfiles((prev) =>
        prev.filter((profile) => profile.rr_id !== rr_id)
      );
      setSelectedProfile(selectedProfiles[0]);
    } else if (response.body.errorType == 'refreshError') {
      addNotification({
        message: 'Your email requires a token refresh; unable to send email.',
        type: 'error',
      });
    } else {
      addNotification({
        message: 'Server error while sending email; please try again later.',
        type: 'error',
      });
    }
    dispatch(fetchUserUsage(uid));
  };

  const scheduleSendEmail = async () => {
    if (selectedDate.isBefore(dayjs())) {
      addNotification({
        message: 'Cannot schedule a draft in the past.',
        type: 'error',
      });
      setSelectedDate(dayjs());
      return;
    }
    if (checkUserExceedUsageLimits()) return;
    const selectedFile =
      profileData[selectedProfile.rr_id]?.selectedUploadedFile?.file;
    let base64File = null;
    if (selectedFile) {
      base64File = await fileToBase64(selectedFile);
    }
    const jsonPayload = {
      uid,
      rrId: selectedProfile.rr_id,
      recipientName: selectedProfile.name,
      recipientDomain: selectedProfile.current_employer_domain,
      companyName: selectedProfile.current_employer,
      subject: profileData[selectedProfile.rr_id]?.selectedDraftSubject,
      body: profileData[selectedProfile.rr_id]?.selectedDraftBody,
      scheduledTime: selectedDate.toISOString(),
      file: base64File,
      fileName: selectedFile?.name,
    };

    addNotification({
      message: `Finding ${selectedProfile.name}'s email and scheduling email...`,
      type: 'info',
    });

    const response = await postMessage('schedule_send_message', jsonPayload);
    if (response.status == 200) {
      addNotification({
        message: 'Email scheduled successfully.',
        type: 'success',
      });
    } else if (response.body.errorType == 'emailNotFound') {
      addNotification({
        message: 'Recipient email not found; unable to schedule email.',
        type: 'error',
      });
      const rr_id = response.rrId;
      setSelectedProfiles((prev) =>
        prev.filter((profile) => profile.rr_id !== rr_id)
      );
      setSelectedProfile(selectedProfiles[0]);
    } else if (response.body.errorType == 'refreshError') {
      addNotification({
        message:
          'Your email requires a token refresh; unable to schedule email.',
        type: 'error',
      });
    } else {
      addNotification({
        message: 'Server error while scheduling email; please try again later.',
        type: 'error',
      });
    }
    dispatch(fetchUserUsage(uid));
  };

  const renderSelectedProfileList = () => {
    return (
      <Box className={styles.profileListContainer}>
        <List>
          {selectedProfiles.map((profile) => (
            <ListItem
              key={profile.rr_id}
              disablePadding
              className={
                selectedProfile && profile.rr_id === selectedProfile.rr_id
                  ? styles.selectedListContainer
                  : styles.unselectedListContainer
              }
            >
              <ListItemButton onClick={() => setSelectedProfile(profile)}>
                <ListItemAvatar>
                  <Avatar
                    src={profile.profile_pic}
                    alt={`${profile.name}'s profile`}
                    className={styles.profileAvatar}
                  />
                </ListItemAvatar>
                <ListItemText primary={profile.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const renderDraftPanel = () => {
    return (
      <Box className={styles.draftPanelContainer}>
        {selectedProfile && (
          <Box>
            <Box className={styles.draftPanelHeaderContainer}>
              <Box className={styles.draftPanelHeaderLeft}>
                <img
                  src={selectedProfile.profile_pic || DefaultProfilePic}
                  alt={`${selectedProfile.name}'s profile`}
                  onError={(e) => {
                    if (!e.target.src.includes(DefaultProfilePic)) {
                      e.target.src = DefaultProfilePic;
                    }
                  }}
                  className={styles.draftPanelHeaderImage}
                />
                <h2 className={styles.draftPanelHeaderText}>
                  {selectedProfile.name}
                </h2>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open(selectedProfile.linkedin_url, '_blank')
                  }
                  className="default-button"
                >
                  View LinkedIn
                </Button>
              </Box>
            </Box>
            <Box className={styles.draftPanelEmailContainer}>
              <TextField
                type="text"
                multiline
                rows={1}
                variant="outlined"
                className={`default-textfield ${styles.draftPanelEmailInput}`}
                value={
                  profileData[selectedProfile.rr_id]?.selectedDraftSubject || ''
                }
                onChange={(e) =>
                  updateProfileData('selectedDraftSubject', e.target.value)
                }
                placeholder={'Your subject line here.'}
                InputProps={{
                  className: styles.draftPanelEmailInputText,
                }}
              />
              <ReactQuill
                theme="snow"
                value={
                  profileData[selectedProfile.rr_id]?.selectedDraftBody || ''
                }
                onChange={(value) =>
                  updateProfileData('selectedDraftBody', value)
                }
                placeholder={'Your populated email draft here.'}
                InputProps={{
                  style: {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '.7rem',
                    padding: '5px',
                  },
                }}
                style={{
                  borderTop: '1px solid #d9d9d9',
                  height: '400px',
                }}
                modules={modules}
                formats={formats}
              />
              <EditorToolbar />
              {/* See if there's a way to decompose out this component into FileInput */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <FileUploadButton
                  uploadRef={fileInputRef}
                  onChange={handleFileChange}
                />
                <Button onClick={handleRemoveAttachment}>
                  {' '}
                  Remove Attachment{' '}
                </Button>
                {profileData[selectedProfile.rr_id]?.selectedUploadedFile && (
                  <p>
                    {
                      profileData[selectedProfile.rr_id]?.selectedUploadedFile
                        .fileName
                    }
                  </p>
                )}
              </Box>
              <Box>{error && <p style={{ color: 'red' }}>{error}</p>}</Box>
              <Box className={styles.reviewButtonContainer}>
                <div className={styles.reviewButtonLeftContainer}>
                  <Button
                    variant="contained"
                    onClick={createDraft}
                    className={`default-button ${styles.reviewButton}`}
                    disabled={
                      !profileData[selectedProfile.rr_id]
                        ?.selectedDraftSubject ||
                      !profileData[selectedProfile.rr_id]?.selectedDraftBody
                    }
                  >
                    Create Draft
                  </Button>
                  <Button
                    variant="contained"
                    onClick={scheduleSendEmail}
                    className={`default-button ${styles.reviewButton}`}
                    disabled={
                      !profileData[selectedProfile.rr_id]
                        ?.selectedDraftSubject ||
                      !profileData[selectedProfile.rr_id]?.selectedDraftBody
                    }
                  >
                    Schedule Send
                  </Button>
                  <Box className={styles.dateTimePickerContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Select Date and Time"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        minDateTime={dayjs().add(60, 'minute')}
                        maxDateTime={dayjs().add(2, 'week')}
                        views={['year', 'month', 'day', 'hours']}
                        shouldDisableDate={isPastDate}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>
                <Button
                  variant="contained"
                  onClick={sendEmail}
                  className={`default-button ${
                    profileData[selectedProfile.rr_id]?.emailSent
                  } ${styles.reviewButton}`}
                  disabled={
                    !profileData[selectedProfile.rr_id]?.selectedDraftSubject ||
                    !profileData[selectedProfile.rr_id]?.selectedDraftBody ||
                    profileData[selectedProfile.rr_id]?.emailSent
                  }
                >
                  {profileData[selectedProfile.rr_id]?.emailSent
                    ? 'Sent'
                    : 'Send Email'}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box className="default-container">
      <NavbarApp />
      <Breadcrumb
        steps={['Query', 'Select', 'Template', 'Review']}
        activeStep={3}
      />
      <Box className={styles.pageBodyContainer}>
        {renderSelectedProfileList()}
        {renderDraftPanel()}
      </Box>
    </Box>
  );
};

export default ReviewPage;
