export const searchPlaceholders = [
  'Google recruiters in San Francisco',
  'Software engineers with 2 years of experience',
  'Berkeley alums who majored in English working in Finance',
  'Analysts at Blackstone',
  'Product managers at Amazon with 5+ years experience',
  'Stanford graduates in Computer Science',
  'Designers with expertise in UI/UX in New York',
  'Marketing directors at startups in Boston',
  'Facebook employees who previously worked at Google',
  'HR specialists in the tech industry in Texas',
  'Engineers with Python and Java skills in Seattle',
  'Yale alumni working in law firms',
  'Executives in companies with revenues over $1M',
  'CEOs at fintech companies with Series B funding',
  'VPs in healthcare companies of size 200-500',
  'Salespeople with experience in cloud services in Chicago',
];
