import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Notifications from '../components/Notifications/Notifications';

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = ({ message, type }) => {
    const newNotification = { id: uuidv4(), message, type };
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };

  const removeNotification = useCallback((notificationId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter(
        (notification) => notification.id !== notificationId
      )
    );
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ addNotification, removeNotification }}
    >
      {children}
      <Notifications
        notifications={notifications}
        removeNotification={removeNotification}
      />
    </NotificationsContext.Provider>
  );
};
