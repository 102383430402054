import React from 'react';

import CheckInputFilter from './CheckInputFilter';
import MultipleInputFilter from './MultipleInputFilter';
import SelectInputFilter from './SelectInputFilter';
import SingleInputFilter from './SingleInputFilter';

const WrappedFilter = ({
  filterConfig,
  sync,
  init,
  collapse,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  const { filterDisplayName, filterName, filterType } = filterConfig;

  if (filterType === 'single_input') {
    return (
      <SingleInputFilter
        filterDisplayName={filterDisplayName}
        filterName={filterName}
        sync={sync}
        init={init}
        collapse={restrictOneOpenFilter}
        restrictOneOpenFilter={restrictOneOpenFilter}
        currOpenFilter={currOpenFilter}
        setCurrOpenFilter={setCurrOpenFilter}
      />
    );
  }

  if (filterType === 'multiple_input') {
    return (
      <MultipleInputFilter
        filterDisplayName={filterDisplayName}
        filterName={filterName}
        sync={sync}
        init={init}
        collapse={collapse}
        restrictOneOpenFilter={restrictOneOpenFilter}
        currOpenFilter={currOpenFilter}
        setCurrOpenFilter={setCurrOpenFilter}
      />
    );
  }

  if (filterType === 'select_input') {
    return (
      <SelectInputFilter
        filterDisplayName={filterDisplayName}
        filterName={filterName}
        filterOptions={filterConfig.filterOptions}
        allowCustom={filterConfig.allowCustom}
        sync={sync}
        init={init}
        collapse={collapse}
        restrictOneOpenFilter={restrictOneOpenFilter}
        currOpenFilter={currOpenFilter}
        setCurrOpenFilter={setCurrOpenFilter}
      />
    );
  }

  if (filterType === 'check_input') {
    return (
      <CheckInputFilter
        filterDisplayName={filterDisplayName}
        filterName={filterName}
        filterOptions={filterConfig.filterOptions}
        sync={sync}
        init={init}
        collapse={collapse}
        restrictOneOpenFilter={restrictOneOpenFilter}
        currOpenFilter={currOpenFilter}
        setCurrOpenFilter={setCurrOpenFilter}
      />
    );
  }
};

export default WrappedFilter;
