import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import styles from './Filters.module.css';

const SingleInputFilter = ({
  filterDisplayName,
  filterName,
  sync,
  init,
  collapse,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  const [filterValue, setFilterValue] = useState(init[filterName] || []);
  const [inputValue, setInputValue] = useState('');
  const [filterOpen, setFilterOpen] = useState(
    restrictOneOpenFilter ? currOpenFilter === filterName : !collapse
  );

  useEffect(() => {
    if (restrictOneOpenFilter) {
      setFilterOpen(currOpenFilter === filterName);
    }
  }, [currOpenFilter, filterName, restrictOneOpenFilter]);

  const handleFilterEnter = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value.trim() === '') {
        return;
      }
      setFilterValue([event.target.value]);
      setInputValue('');
      sync((prev) => ({ ...prev, [filterName]: [event.target.value] }));
    }
  };

  const handleFilterRemove = () => {
    setFilterValue([]);
    sync((prev) => ({ ...prev, [filterName]: [] }));
  };

  const toggleFilterOpen = () => {
    if (restrictOneOpenFilter) {
      if (currOpenFilter === filterName) {
        setCurrOpenFilter('');
      } else {
        setCurrOpenFilter(filterName);
      }
    } else {
      setFilterOpen(!filterOpen);
    }
  };

  return (
    <Box className={styles.filterContainer}>
      <Box className={styles.filterInputContainer}>
        <Typography gutterBottom>{filterDisplayName}</Typography>
        {collapse &&
          (filterOpen ? (
            <ExpandMoreIcon onClick={toggleFilterOpen} />
          ) : (
            <ChevronRightIcon onClick={toggleFilterOpen} />
          ))}
      </Box>
      {filterOpen ? (
        <TextField
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleFilterEnter}
          variant="outlined"
          className={styles.filterSelectContainer}
        />
      ) : (
        <div />
      )}
      <Box className={styles.filterChipContainer}>
        {filterValue.length > 0 && (
          <Chip
            key={filterValue[0]}
            label={filterValue[0]}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: 'grey', margin: 2 }}
            onDelete={() => handleFilterRemove()}
          />
        )}
      </Box>
    </Box>
  );
};

export default SingleInputFilter;
