import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FireOffLogo from '../../assets/FireOffLogo.webp';
import FireOffTextLogo from '../../assets/FireOffTextLogo.webp';
import { firebase } from '../../core/firebase.js';
import { getMessage } from '../../core/server.js';
import { unsetThreads } from '../../redux/reducers/ThreadsReducer.js';
import { unsetUser } from '../../redux/reducers/UserReducer.js';
import ToolTip from '../ToolTip/ToolTip.js';
import styles from './Navbar.module.css';

export default function NavbarApp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useSelector((state) => state.user.data) || {};
  const limits = useSelector((state) => state.user.limits) || {};
  const usage = useSelector((state) => state.user.usage) || {};
  const { uid = '' } = userData;

  const getUsageTextList = () => {
    return limits
      ? [
          `Monthly Email Credits: ${
            limits.monthly_email_credit_limit - usage.monthly_email_credit_usage
          } / ${limits.monthly_email_credit_limit} Remain`,
          `Daily Email Credits: ${
            limits.daily_email_credit_limit - usage.daily_email_credit_usage
          } / ${limits.daily_email_credit_limit} Remain`,
        ]
      : [];
  };

  useEffect(() => {
    const pingServer = async () => {
      try {
        if (uid) {
          await getMessage('ping', { uid });
        } else {
          await getMessage('ping');
        }
      } catch (error) {
        console.error(error);
      }
    };

    const serverPingInterval = setInterval(pingServer, 10000);
    pingServer();

    return () => clearInterval(serverPingInterval);
  }, []);

  const onClickSignOut = async () => {
    try {
      await firebase.signOut().then(() => {
        dispatch(unsetUser());
        dispatch(unsetThreads());
        window.location.href = '/';
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Box className={styles.headerWrapper}>
        <AppBar position="fixed" className={styles.appBar}>
          <Toolbar className={styles.toolbar}>
            <div className={`${styles.headerLeft}`}>
              <img src={FireOffLogo} alt="Logo" className={styles.logo} />
              <img
                src={FireOffTextLogo}
                alt="Word Logo"
                className={styles.wordLogo}
              />
            </div>
            <div className={`${styles.headerRight} center`}>
              <ToolTip textList={getUsageTextList()}>
                <span
                  className={`fa fa-question-circle ${styles.questionMarkIcon}`}
                />
              </ToolTip>
              <a
                className={`${styles.title} `}
                onClick={() => navigate('/a/query')}
              >
                Search
              </a>
              <a
                className={`${styles.title} `}
                onClick={() => navigate('/a/dashboard')}
              >
                Dashboard
              </a>
              <a
                className={`${styles.title} `}
                onClick={() => navigate('/a/account')}
              >
                Account
              </a>
              <a className={styles.title} onClick={handleMenuClick}>
                <MenuIcon />
              </a>
              <Menu
                open={menuOpen}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
              >
                <MenuItem onClick={onClickSignOut}>
                  <a className={styles.signOut}>Sign Out</a>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
