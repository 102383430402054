import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import Pricing from '../../../components/Pricing/Pricing.js';
import AccountSideBar from '../../../components/SideBar/AccountSideBar.js';
import { postMessage } from '../../../core/server.js';

const UserPlanPage = () => {
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '', email = '' } = userData;

  const planFunctions = {
    Spark: () => redirectToPurchase('spark'),
    Flame: () => redirectToPurchase('flame'),
    Inferno: () => navigate('/contact'),
  };

  const redirectToPurchase = async (plan) => {
    const response = await postMessage(`/create_checkout_session_${plan}`, {
      uid,
      email,
    });
    if (response.status == 200) {
      window.location.href = response.body.url;
    }
  };

  return (
    <div className="default-sidebar-page-container">
      <NavbarApp />
      <div className="default-sidebar-nav-container">
        <AccountSideBar />
      </div>
      <Box className="default-sidebar-main-container">
        <Pricing planFunctions={planFunctions} />
      </Box>
    </div>
  );
};

export default UserPlanPage;
