import React, { Fragment } from 'react';

import { pricingPlans } from '../../constants/pricingPlans';
import ToolTip from '../ToolTip/ToolTip';
import styles from './Pricing.module.css';

const betaText = [
  'We are in our beta. When this period ends, we will no longer offer this price to new members.',
];

const Pricing = (planFunctions) => {
  const functions = planFunctions.planFunctions;

  const handlePurchaseClick = (feature) => {
    const functionToCall = functions[feature];
    if (functionToCall) {
      functionToCall();
    } else {
      console.warn(`No function defined for feature: ${feature}`);
    }
  };

  const renderPrice = (feature, newPrice) => {
    if (feature == 'Inferno') {
      return (
        <div className={styles.newPrice}>
          <p>{newPrice}</p>
        </div>
      );
    } else {
      return (
        <ToolTip textList={betaText}>
          <div className={styles.newPrice}>
            <p>
              {newPrice}{' '}
              <span
                className={`fa fa-question-circle ${styles.questionMarkIcon}`}
              ></span>
            </p>
          </div>
        </ToolTip>
      );
    }
  };

  return (
    <div id="Pricing" className={`${styles.pricesWrapper} center`}>
      <div className={styles.pricesHeading}>
        <p>Plans & Pricing</p>
      </div>
      <div className={`${styles.pricesListWrapper} center`}>
        <div className={`${styles.pricesList} center`}>
          {pricingPlans.map(
            (
              { price, description, feature, purchase, icon, newPrice },
              index
            ) => {
              return (
                <div className={`${styles.priceDiv} center`} key={index}>
                  <Fragment>
                    <div className={styles.price}>
                      <i className={`fa ${icon} ${styles.priceIcon}`}></i>
                    </div>

                    <div className={styles.pricePlanName}>
                      <p>{feature}</p>
                    </div>

                    <div className={styles.priceAmount}>
                      <p>{price}</p>
                    </div>
                    {renderPrice(feature, newPrice)}
                    <div
                      className={styles.shopBtn}
                      onClick={() => handlePurchaseClick(feature)}
                    >
                      <p>{purchase}</p>
                    </div>

                    <div className={styles.priceDescription}>
                      {' '}
                      {description.map((desc, descIndex) => (
                        <div key={descIndex} className={styles.descriptionItem}>
                          <i
                            className={`${desc.iconL} ${styles.descriptionIcon}`}
                          ></i>
                          <span>{desc.text}</span>
                        </div>
                      ))}
                    </div>
                  </Fragment>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
