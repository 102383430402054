import { configureStore } from '@reduxjs/toolkit';

import templatesReducer from '../redux/reducers/TemplatesReducer.js';
import threadsReducer from '../redux/reducers/ThreadsReducer.js';
import userReducer from '../redux/reducers/UserReducer.js';

export default configureStore({
  reducer: {
    user: userReducer,
    templates: templatesReducer,
    threads: threadsReducer,
  },
});
