import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NavbarApp from '../../../components/Navbar/NavbarApp.js';

const OutcomePage = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <Box className="default-container">
      <NavbarApp />
      <h2>
        {message ? message : "You seemed to have lost your way. Let's go back."}
      </h2>
      <Button className="default-button" onClick={() => navigate('/a/account')}>
        Return Home
      </Button>
    </Box>
  );
};

export default OutcomePage;
