import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signOut as firebaseSignOut,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: 'AIzaSyDS2LwY1e6N5msVeksMdIEKWbmvncng3PU',
  authDomain: 'searcher-fe810.firebaseapp.com',
  projectId: 'searcher-fe810',
  storageBucket: 'searcher-fe810.appspot.com',
  messagingSenderId: '906360443910',
  appId: '1:906360443910:web:c24396d02af0f106ff9f87',
};

class FirebaseManager {
  constructor(firebaseConfig) {
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
    this.auth = getAuth(this.app);
    this.auth.onAuthStateChanged(this.onAuth);
    this.loadCurrentUserFromCookie();
  }

  loadCurrentUserFromCookie() {
    const userCookie = Cookies.get('currentUser');
    if (userCookie) {
      this.currentUser = JSON.parse(userCookie);
    }
  }

  onAuth = async (user) => {
    const consentGiven = localStorage.getItem('cookieConsent') === 'true';
    if (user) {
      this.currentUser = user;
      if (consentGiven) {
        Cookies.set(
          'currentUser',
          JSON.stringify({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          }),
          { expires: 7 }
        );
      }
    } else {
      this.currentUser = null;
      Cookies.remove('currentUser');
    }
  };

  signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(this.auth, provider);
    } catch (error) {
      throw error;
    }
  };

  signInWithMicrosoft = async () => {
    const provider = new OAuthProvider('microsoft.com');
    try {
      await signInWithPopup(this.auth, provider);
    } catch (error) {
      throw error;
    }
  };

  createUserWithEmail = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  signInWithEmail = async (email, password) => {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  async signOut() {
    await firebaseSignOut(this.auth);
    Cookies.remove('currentUser');
  }
}

export const firebase = new FirebaseManager(firebaseConfig);
