export const pricingPlans = [
  {
    icon: 'fa-solid fa-bolt',
    feature: 'Spark',
    price: '$11.99/month',
    newPrice: 'Beta Offer: Free',
    description: [
      { iconL: 'fa-solid fa-circle-check', text: ' 300 Email Credits/month' },
      { iconL: 'fa-solid fa-circle-check', text: ' Unlimited People Searches' },
      { iconL: 'fa-solid fa-circle-check', text: ' Unlimited AI Credits' },
    ],
    purchase: 'Get started',
  },
  {
    icon: 'fa-solid fa-fire',
    feature: 'Flame',
    price: '$14.99/month',
    newPrice: 'Beta Offer: $1.99/month',
    description: [
      { iconL: 'fa-solid fa-circle-check', text: ' 1200 Email Credits/month' },
      { iconL: 'fa-solid fa-circle-check', text: ' Outreach Dashboard' },
      { iconL: 'fa-solid fa-circle-check', text: ' Email Tracking Tools' },
    ],

    purchase: 'Get started',
  },

  {
    icon: 'fa-solid fa-sun',
    feature: 'Inferno',
    // price: "\u200B",
    price: 'Standard Rate/month',
    newPrice: 'Bulk Discounts',
    description: [
      { iconL: 'fa-solid fa-circle-check', text: ' Custom Credits/month' },
      {
        iconL: 'fa-solid fa-circle-check',
        text: ' Advanced Tracking Analytics',
      },
      { iconL: 'fa-solid fa-circle-check', text: ' AI Inbox Manager' },
    ],

    purchase: 'Contact Sales',
  },
];
