import DemoVideo from '../../assets/FireOffDemoVideo.mov';
import { productFeatures } from '../../constants/productFeatures';
import styles from './Product.module.css';
import ProductBox from './ProductBox';

const Product = () => {
  return (
    <div id="Product" className={`${styles.growthWrapper} center`}>
      <div className={`${styles.growthWrapperInner} center`}>
        <div className={styles.growthHeading}>
          <p>See how it works in under 30 seconds</p>
        </div>
        <div className={styles.growthImg}>
          <video controls>
            <source src={DemoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={`${styles.growthList} center`}>
          {productFeatures.map(({ field, description }, index) => {
            return (
              <ProductBox key={index} field={field} description={description} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Product;
