export const supportedFilters = [
  {
    categoryName: 'Name',
    filters: [
      {
        filterDisplayName: 'Name',
        filterName: 'name',
        filterType: 'single_input',
      },
    ],
  },
  {
    categoryName: 'Education',
    filters: [
      {
        filterDisplayName: 'School / University',
        filterName: 'school',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Degree',
        filterName: 'degree',
        filterType: 'select_input',
        filterOptions: [
          'High School',
          'Associates',
          'Bachelors',
          'Masters',
          'Doctorates',
        ],
        allowCustom: true,
      },
    ],
  },
  {
    categoryName: 'Occupation',
    filters: [
      {
        filterDisplayName: 'Current Title(s)',
        filterName: 'current_title',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Skills',
        filterName: 'skills',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Years of Experience',
        filterName: 'years_experience',
        filterType: 'select_input',
        filterOptions: ['<1', '1-3', '4-10', '10+'],
        allowCustom: true,
      },
      {
        filterDisplayName: 'Management Level',
        filterName: 'management_levels',
        filterType: 'check_input',
        filterOptions: [
          'Founder/Owner',
          'C-Level',
          'VP',
          'Director',
          'Manager',
          'Individual Contributor',
          'Entry',
          'Intern',
          'Unpaid',
        ],
      },
      {
        filterDisplayName: 'Industry',
        filterName: 'industry',
        filterType: 'check_input',
        filterOptions: [
          {
            name: 'Agriculture & Fishing (5)',
            subcategories: [
              'Agriculture',
              'Crops',
              'Farming Animals & Livestock',
              'Fishery & Aquaculture',
              'Ranching',
            ],
          },
          {
            name: 'Business Services (25)',
            subcategories: [
              'Accounting & Accounting Services',
              'Auctions',
              'Business Services - General',
              'Call Centers & Business Centers',
              'Career Planning',
              'Career',
              'Commercial Printing',
              'Debt Collection',
              'Design',
              'Event Services',
              'Facilities Management & Services',
              'Food Service',
              'Fraud Detection',
              'Geography & Positioning',
              'Human Resources & Staffing',
              'Information Services',
              'Management Consulting',
              'Marketing & Advertising',
              'Multimedia & Graphic Design',
              'Outsourcing',
              'Sales',
              'Security & Investigations Products & Services',
              'Staffing & Recruiting',
              'Translation & Localization',
              'Writing & Editing',
            ],
          },
          {
            name: 'Construction (4)',
            subcategories: [
              'Architecture Engineering & Design',
              'Construction - General',
              'Mechanical Engineering',
              'Mechanical or Industrial Engineering',
            ],
          },
          {
            name: 'Consumer Services (11)',
            subcategories: [
              'Car & Truck Rental',
              'Child Care',
              'Consumer Services - General',
              'Funeral Home & Funeral Related Services',
              'Hair Salons',
              'Laundry & Dry Cleaning Services',
              'Photography',
              'Privacy',
              'Travel Agencies & Services',
              'Veterinary Care',
              'Wedding',
            ],
          },
          {
            name: 'Education (7)',
            subcategories: [
              'College & Universities',
              'E-Learning',
              'Education - General',
              'Higher Education',
              'K-12 Schools',
              'Professional Training & Coaching',
              'Training',
            ],
          },
          {
            name: 'Energy, Utilities & Waste Treatment (10)',
            subcategories: [
              'Electricity & Energy',
              'Environmental Services',
              'Forestry',
              'Natural Resources',
              'Nuclear',
              'Oil & Gas Exploration & Services',
              'Renewables & Environment',
              'Utilities',
              'Water Energy & Waste Treatment',
              'Energy, Utilities & Waste Treatment - General',
            ],
          },
          {
            name: 'Finance (6)',
            subcategories: [
              'Banking',
              'Credit Cards & Transaction Processing',
              'Finance - General',
              'Financial Services',
              'Investment Banking',
              'Venture Capital & Private Equity',
            ],
          },
          {
            name: 'Government & Public Services (12)',
            subcategories: [
              'Chambers of Commerce',
              'Cities Towns & Municipalities - General',
              'Communities',
              'Corrections Facilities',
              'Government - General',
              'Local',
              'International Affairs',
              'Law Enforcement',
              'Military',
              'Parking',
              'Public Policy',
              'Public Safety',
            ],
          },
          {
            name: 'Healthcare (12)',
            subcategories: [
              'Biotechnology',
              'Clinical Trials',
              'Drug Manufacturing & Research',
              'Drug Stores & Pharmacies',
              'Emergency Medical Transportation & Services',
              'Healthcare - General',
              'Hospitals & Healthcare',
              'Medical Practice',
              'Medicine',
              'Mental Health Care',
              'Pharmaceuticals',
              'Psychology',
            ],
          },
          {
            name: 'Leisure & Hospitality (20)',
            subcategories: [
              'Amusement Parks Arcades & Attractions',
              'Cruise Lines',
              'Cultural - General',
              'Elder & Disabled Care',
              'Entertainment',
              'Fine Arts',
              'Fitness & Dance Facilities',
              'Gambling & Casinos',
              'Gaming',
              'Hospitality - General',
              'Hotels & Resorts',
              'Libraries',
              'Lodging & Resorts',
              'Movie Theaters',
              'Museums & Art Galleries',
              'Performing Arts',
              'Recreation',
              'Restaurants',
              'Sports',
              'Zoos & National Parks',
            ],
          },
          'Insurance',
          'Law Firms & Legal Services',
          {
            name: 'Manufacturing (49)',
            subcategories: [
              'Aerospace & Defense',
              'Apparel & Fashion',
              'Appliances',
              'Automotive',
              'Boats & Submarines',
              'Building Materials',
              'Business Suplies & Equipment',
              'Chemicals',
              'Cleaning Products',
              'Computer Hardware',
              'Consumer Electronics',
              'Consumer Goods',
              'Cosmetics Beauty Supply & Personal Care Products',
              'Dairy',
              'Electronics & Electronics Manufacturing',
              'Flash Storage',
              'Food & Beverages',
              'Furniture',
              'Glass & Concrete',
              'Hand Power and Lawn-care Tools',
              'Health & Nutrition Products',
              'Health Wellness & Fitness',
              'Household Goods',
              'Industrial Machinery Equipment & Automation',
              'Lumber & Wood Production',
              'Luxury Goods & Jewelry',
              'Manufacturing - General',
              'Maritime',
              'Medical Devices & Equipment',
              'Miscellaneous Building Materials',
              'Network Security Hardware & Software',
              'Office Products',
              'Paper & Forest Products',
              'Personal Computers & Peripherals',
              'Pet Products',
              'Petrochemicals',
              'Plastics & Packaging & Containers',
              'Plumbing & HVAC Containers',
              'Power Conversion & Protection Equipment',
              'Semiconductor & Semiconductor Equipment',
              'Shipbuilding',
              'Telecommunication Equipment',
              'Test & Measurement Equipment',
              'Textiles & Apparel',
              'Tires & Rubber',
              'Tobacco',
              'Toys & Games',
              'Wine & Spirits',
              'Wire & Cabel',
            ],
          },
          {
            name: 'Media & Internet (22)',
            subcategories: [
              'Adult',
              'Animation',
              'Broadcasting & Media',
              'Classifieds',
              'Ebook & Audiobooks',
              'Ecommerce & Internet Retail',
              'Film Video & Media Production & Services',
              'Internet & Digital Media',
              'Internet-related Services',
              'Music & Music Related Services',
              'Newspapers & News Services',
              'Podcast',
              'Public Relations & Communication',
              'Publishing',
              'Radio Stations',
              'Search Enginers & Internet Portals',
              'Social Media',
              'Software Engineering',
              'Streaming',
              'Television Stations',
              'Video Chat & Online Messaging',
              'Media & Internet - General',
            ],
          },
          {
            name: 'Metals & Mining (2)',
            subcategories: ['Metals & Mining - General', 'Mining'],
          },
          {
            name: 'Organizations (5)',
            subcategories: [
              'Non-Profit & Charitable Organizations & Foundations',
              'Organizations - General',
              'Parenting & Youth',
              'Philanthropy',
              'Religious Organizations',
            ],
          },
          {
            name: 'Real Estate (2)',
            subcategories: ['Commercial Real Estate', 'Real Estate - General'],
          },
          {
            name: 'Research & Technology (15)',
            subcategories: [
              'AR/VR',
              'Artificial Intelligence & Machine Learning',
              'Cryptocurrency',
              'Data & Big Data',
              'Drones',
              'Genetics',
              'Mobile',
              'Nanotechnology',
              'Neuroscience',
              'Quantum Computing',
              'Research - General',
              'Robotics',
              'Sharing Technology',
              'Technology',
              'Think Tanks',
            ],
          },
          {
            name: 'Retail (17)',
            subcategories: [
              'Department Stores Shopping Centers & Superstores',
              'Flowers Gifts & Specialty',
              'Footear',
              'Gas Stations Convenience & Liquor Stores',
              'Gift & Gift Products',
              'Grocery & Supermarkets',
              'Home Improvement & Hardware',
              'Optometry & Eyewear',
              'Rental - Other - Futniture A/V Construction & Industrial Equipment',
              'Rental - Video & DVD',
              'Retail - General',
              'Sporting & Recreational Equipment',
              'Vitamins Supplements & Health Stores',
              'Record, Video & Book Stores',
              'Jewelry & Watch Retail',
              'Consumer Electronics & Computers Retail',
              'Apparel & Accessories Retail',
            ],
          },
          {
            name: 'IT & Software (11)',
            subcategories: [
              'Business Intelligence Software',
              'Cloud Software',
              'Computer Games',
              'Content & Collaboration Software',
              'Cusomter Relationship Management Software(CRM)',
              'Database & File Management Software',
              'Enterprise Resource Planning Software(ERP)',
              'Software & Technical Consulting',
              'Software - General',
              'Storage & Systems Management Software',
              'Supply Chain Management Software',
            ],
          },
          {
            name: 'Telecommunications (3)',
            subcategories: [
              'Cable & Satellite',
              'Telecommunications - General',
              'Wireless',
            ],
          },
          {
            name: 'Supply Chain & Logistics (4)',
            subcategories: [
              'Import & Export & Trade',
              'Logistics & Supply Chain',
              'Warehousing',
              'Wholesale',
            ],
          },
          {
            name: 'Transportation (7)',
            subcategories: [
              'Airlines & Aviation',
              'Delivery',
              'Freight & Logistics Services',
              'Rail Bus & Taxi',
              'Shipping',
              'Transportation - General',
              'Trucking Moving & Storage',
            ],
          },
        ],
        allowCustom: true,
      },
    ],
  },
  {
    categoryName: 'Role or Department',
    filters: [
      {
        filterDisplayName: 'Department',
        filterName: 'department',
        filterType: 'check_input',
        filterOptions: [
          {
            name: 'Engineering',
            subcategories: [
              'Graphic Design',
              'Product Design',
              'Web Design',
              'Data',
              'Devops',
              'Electrical',
              'Mechanical',
              'Network',
              'Information Technology',
              'Project Engineering',
              'Quality Assurance',
              'Security',
              'Software',
              'Systems',
              'Web',
            ],
          },
          {
            name: 'Finance',
            subcategories: ['Accounting', 'Investment', 'Tax'],
          },
          {
            name: 'HR',
            subcategories: [
              'Compensation',
              'Employee Development',
              'Recruiting',
            ],
          },
          { name: 'Legal', subcategories: ['Judicial', 'Lawyer', 'Paralegal'] },
          {
            name: 'Marketing',
            subcategories: [
              'Brand Marketing',
              'Content Marketing',
              'Product Marketing',
              'Events',
              'Media Relations',
              'Broadcasting',
              'Editorial',
              'Journalism',
              'Video',
              'Writing',
            ],
          },
          {
            name: 'Health',
            subcategories: [
              'Dental',
              'Doctor',
              'Fitness',
              'Nursing',
              'Therapy',
              'Wellness',
            ],
          },
          {
            name: 'Operations',
            subcategories: [
              'Logistics',
              'Office Management',
              'Product',
              'Project Management',
              'Customer Success',
              'Support',
            ],
          },
          {
            name: 'Sales',
            subcategories: ['Accounts', 'Business Development', 'Pipeline'],
          },
        ],
      },
    ],
  },
  {
    categoryName: 'Location',
    filters: [
      {
        filterDisplayName: 'Location(s)',
        filterName: 'location',
        filterType: 'check_input',
        filterOptions: [
          {
            name: 'US > States (56)',
            subcategories: [
              'Alabama, US',
              'Alaska, US',
              'Arizona, US',
              'Arkansas, US',
              'California, US',
              'Colorado, US',
              'Connecticut, US',
              'Delaware, US',
              'Florida, US',
              'Georgia, US',
              'Hawaii, US',
              'Idaho, US',
              'Illinois, US',
              'Indiana, US',
              'Iowa, US',
              'Kansas, US',
              'Kentucky, US',
              'Louisiana, US',
              'Maine, US',
              'Maryland, US',
              'Massachusetts, US',
              'Michigan, US',
              'Minnesota, US',
              'Mississippi, US',
              'Missouri, US',
              'Montana, US',
              'Nebraska, US',
              'Nevada, US',
              'New Hampshire, US',
              'New Jersey, US',
              'New Mexico, US',
              'New York, US',
              'North Carolina, US',
              'North Dakota, US',
              'Ohio, US',
              'Oklahoma, US',
              'Oregon, US',
              'Pennsylvania, US',
              'Rhode Island, US',
              'South Carolina, US',
              'South Dakota, US',
              'Tennessee, US',
              'Texas, US',
              'Utah, US',
              'Vermont, US',
              'Virginia, US',
              'Washington, US',
              'West Virginia, US',
              'Wisconsin, US',
              'Wyoming, US',
              'District of Columbia',
              'American Samoa',
              'Canal Zone',
              'Guam',
              'Puerto Rico',
              'Virgin Islands',
            ],
          },
          {
            name: 'US > Metro Areas (217)',
            subcategories: [
              'AK > Anchorage Area',
              'AK > Fairbanks Area',
              'AL > Anniston Area',
              'AL > Birmingham Area',
              'AL > Dothan Area',
              'AL > Huntsville Area',
              'AL > Mobile Area',
              'AL > Montgomery Area',
              'AL > Tuscaloosa Area',
              'AR > Fort Smith Area',
              'AR > Jonesboro Area',
              'AR > Little Rock Area',
              'AZ > Phoenix Area',
              'AZ > Tucson Area',
              'AZ > Yuma Area',
              'CA > Bakersfield Area',
              'CA > Chico Area',
              'CA > Eureka Area',
              'CA > Fresno Area',
              'CA > Irvine Area',
              'CA > Los Angeles Area',
              'CA > Monterey Bay Area',
              'CA > Palm Springs Area',
              'CA > Sacramento Area',
              'CA > San Diego Area',
              'CA > San Francisco Area',
              'CA > San Jose Area',
              'CA > Santa Barbara Area',
              'CO > Colorado Springs Area',
              'CO > Denver Area',
              'CO > Grand Junction Area',
              'CT > Hartford Area',
              'DC > Washington Area',
              'FL > Fort Myers Area',
              'FL > Gainesville Area',
              'FL > Jacksonville Area',
              'FL > Miami Area',
              'FL > Orlando Area',
              'FL > Panama City Area',
              'FL > Tallahassee Area',
              'FL > Tampa Area',
              'FL > West Palm Beach Area',
              'GA > Albany Area',
              'GA > Atlanta Area',
              'GA > Augusta Area',
              'GA > Columbus Area',
              'GA > Macon Area',
              'GA > Savannah Area',
              'HI > Honolulu Area',
              'IA > Cedar Rapids Area',
              'IA > Davenport Area',
              'IA > Des Moines Area',
              'IA > Ottumwa Area',
              'IA > Sioux City Area',
              'ID > Boise City Area',
              'ID > Idaho Falls Area',
              'ID > Twin Falls Area',
              'IL > Chicago Area',
              'IL > Peoria Area',
              'IL > Quincy Area',
              'IL > Rockford Area',
              'IL > Springfield Area',
              'IN > Evansville Area',
              'IN > Fort Wayne Area',
              'IN > Indianapolis Area',
              'IN > Lafayette Area',
              'IN > South Bend Area',
              'IN > Terre Haute Area',
              'KS > Topeka Area',
              'KS > Wichita Area',
              'KY > Bowling Green Area',
              'KY > Lexington Area',
              'KY > Louisville Area',
              'KY > Paducah Area',
              'LA > Alexandria Area',
              'LA > Baton Rouge Area',
              'LA > Lafayette Area',
              'LA > Lake Charles Area',
              'LA > Monroe Area',
              'LA > New Orleans Area',
              'LA > Shreveport Area',
              'MA > Boston Area',
              'MA > Springfield Area',
              'MD > Baltimore Area',
              'MD > Salisbury Area',
              'ME > Bangor Area',
              'ME > Portland Area',
              'ME > PresqueIsle Area',
              'MI > Alpena Area',
              'MI > Detroit Area',
              'MI > Flint Area',
              'MI > Grand Rapids Area',
              'MI > Lansing Area',
              'MI > Marquette Area',
              'MI > Traverse City Area',
              'MN > Duluth Area',
              'MN > Mankato Area',
              'MN > Minneapolis Area',
              'MN > Rochester Area',
              'MO > Jefferson City Area',
              'MO > Joplin Area',
              'MO > Kansas City Area',
              'MO > Saint Joseph Area',
              'MO > Saint Louis Area',
              'MO > Springfield Area',
              'MS > Biloxi Area',
              'MS > Columbus Area',
              'MS > Greenville Area',
              'MS > Hattiesburg Area',
              'MS > Jackson Area',
              'MS > Meridian Area',
              'MT > Billings Area',
              'MT > Butte Area',
              'MT > Glendive Area',
              'MT > Great Falls Area',
              'MT > Helena Area',
              'MT > Missoula Area',
              'NC > Charlotte Area',
              'NC > Greenville Area',
              'NC > Raleigh Area',
              'NC > Wilmington Area',
              'NC > Winston Salem Area',
              'ND > Bismarck Area',
              'ND > Fargo Area',
              'NE > Lincoln Area',
              'NE > North Platte Area',
              'NE > Omaha Area',
              'NM > Albuquerque Area',
              'NV > Las Vegas Area',
              'NV > Reno Area',
              'NY > Albany Area',
              'NY > Binghamton Area',
              'NY > Buffalo Area',
              'NY > Elmira Area',
              'NY > New York Area',
              'NY > Rochester Area',
              'NY > Syracuse Area',
              'NY > Utica Area',
              'NY > Watertown Area',
              'OH > Cincinnati Area',
              'OH > Cleveland Area',
              'OH > Columbus Area',
              'OH > Dayton Area',
              'OH > Lima Area',
              'OH > Toledo Area',
              'OH > Wheeling Area',
              'OH > Youngstown Area',
              'OH > Zanesville Area',
              'OK > Ardmore Area',
              'OK > Oklahoma City Area',
              'OK > Tulsa Area',
              'OR > Bend Area',
              'OR > Eugene Area',
              'OR > Medford Area',
              'OR > Portland Area',
              'PA > Erie Area',
              'PA > Harrisburg Area',
              'PA > Johnstown Area',
              'PA > Philadelphia Area',
              'PA > Pittsburgh Area',
              'PA > Wilkes Barre Area',
              'PR > Aguadilla Area',
              'PR > Mayaguez Area',
              'PR > Ponce Area',
              'PR > San Juan Area',
              'RI > Providence Area',
              'SC > Charleston Area',
              'SC > Columbia Area',
              'SC > Florence Area',
              'SC > Greenville Area',
              'SC > Rapid City Area',
              'SC > Sioux Falls Area',
              'TN > Chattanooga Area',
              'TN > Jackson Area',
              'TN > Knoxville Area',
              'TN > Memphis Area',
              'TN > Nashville Area',
              'TN > Tri Cities Area',
              'TX > Abilene Area',
              'TX > Amarillo Area',
              'TX > Austin Area',
              'TX > Beaumont Area',
              'TX > Bryan Area',
              'TX > Corpus Christi Area',
              'TX > Dallas Area',
              'TX > El Paso Area',
              'TX > Harlingen Area',
              'TX > Houston Area',
              'TX > Laredo Area',
              'TX > Lubbock Area',
              'TX > Odessa Area',
              'TX > San Angelo Area',
              'TX > San Antonio Area',
              'TX > Tyler Area',
              'TX > Victoria Area',
              'TX > Wichita Falls Area',
              'UT > Salt Lake City Area',
              'VA > Charlottesville Area',
              'VA > Hampton Roads Area',
              'VA > Harrisonburg Area',
              'VA > Richmond Area',
              'VA > Roanoke Area',
              'VT > Burlington Area',
              'WA > Seattle Area',
              'WA > Spokane Area',
              'WA > Tri Cities Area',
              'WI > Green Bay Area',
              'WI > La Crosse Area',
              'WI > Madison Area',
              'WI > Milwaukee Area',
              'WI > Wausau Area',
              'WV > Bluefield Area',
              'WV > Charleston Area',
              'WV > Clarksburg Area',
              'WY > Parkersburg Area',
              'WY > Casper Area',
              'WY > Cheyenne Area',
            ],
          },
          {
            name: 'Canada > Provinces (13)',
            subcategories: [
              'Alberta',
              'British Columbia',
              'Manitoba',
              'New Brunswick',
              'Newfoundland',
              'Northwest Territories',
              'Nova Scotia',
              'Nunavut',
              'Ontario',
              'Prince Edward Island',
              'Quebec',
              'Saskatchewan',
              'Yukon',
            ],
          },
          {
            name: 'Canada > Metro Areas (34)',
            subcategories: [
              'AB > Calgary Area',
              'AB > Edmonton Area',
              'BC > Abbotsford Area',
              'BC > Kelowna Area',
              'BC > Vancouver Area',
              'BC > Victoria Area',
              'MB > Winnipeg Area',
              'NB > Moncton Area',
              'NB > St. John Area',
              'NL > St. John Area',
              'NS > Halifax Area',
              'NT > Yellowknife Area',
              'ON > Barrie Area',
              'ON > Brantford Area',
              'ON > Guelph Area',
              'ON > Hamilton Area',
              'ON > Kingston Area',
              'ON > Kitchener Area',
              'ON > London Area',
              'ON > Ottawa Area',
              'ON > Peterborough Area',
              'ON > St. Catharines Area',
              'ON > Sudbury Area',
              'ON > Thunder Bay Area',
              'ON > Toronto Area',
              'ON > Windsor Area',
              'PEI > Charlottetown Area',
              'QC > Chicoutimi Area',
              'QC > Montreal Area',
              'QC > Quebec Area',
              'QC > Sherbrooke Area',
              'QC > Trois Rivieres Area',
              'SK > Regina Area',
              'SK > Saskatoon Area',
            ],
          },
          {
            name: 'Africa (52)',
            subcategories: [
              'Algeria',
              'Angola',
              'Benin',
              'Botswana',
              'Burkina Faso',
              'Burundi',
              'Cabo Verde',
              'Cameroon',
              'Central African Republic',
              'Chad',
              'Comoros',
              'Congo (DRC)',
              'Congo (Republic)',
              'Djibouti',
              'Egypt',
              'Equatorial Guinea',
              'Eritrea',
              'Ethiopia',
              'Gabon',
              'Gambia',
              'Ghana',
              'Guinea',
              'Guinea-Bissau',
              'Kenya',
              'Lesotho',
              'Liberia',
              'Libya',
              'Madagascar',
              'Malawi',
              'Mali',
              'Mauritania',
              'Mauritius',
              'Morocco',
              'Mozambique',
              'Namibia',
              'Niger',
              'Nigeria',
              'Rwanda',
              'Sao Tome and Principe',
              'Senegal',
              'Seychelles',
              'Sierra Leone',
              'Somalia',
              'South Africa',
              'Sudan',
              'Swaziland',
              'Tanzania',
              'Togo',
              'Tunisia',
              'Uganda',
              'Zambia',
              'Zimbabwe',
            ],
          },
          {
            name: 'Asia (45)',
            subcategories: [
              'Afghanistan',
              'Armenia',
              'Azerbaijan',
              'Bahrain',
              'Bangladesh',
              'Bhutan',
              'Brunei Darussalam',
              'Cambodia',
              'China',
              'Georgia (C)',
              'India',
              'Indonesia',
              'Iran',
              'Iraq',
              'Israel',
              'Japan',
              'Jordan',
              'Kazakhstan',
              'Kuwait',
              'Kyrgyzstan',
              'Laos',
              'Lebanon',
              'Malaysia',
              'Maldives',
              'Mongolia',
              'Myanmar',
              'Nepal',
              'North Korea',
              'Oman',
              'Pakistan',
              'Philippines',
              'Qatar',
              'Saudi Arabia',
              'Singapore',
              'South Korea',
              'Sri Lanka',
              'Syria',
              'Taiwan',
              'Tajikistan',
              'Thailand',
              'Turkey',
              'Turkmenistan',
              'United Arab Emirates',
              'Uzbekistan',
              'Vietnam',
              'Yemen',
            ],
          },
          {
            name: 'Europe (42)',
            subcategories: [
              'Albania',
              'Andorra',
              'Austria',
              'Belarus',
              'Belgium',
              'Bosnia Herzegovina',
              'Bulgaria',
              'Croatia',
              'Cyprus',
              'Czech Republic',
              'Denmark',
              'Estonia',
              'Finland',
              'France',
              'Germany',
              'Greece',
              'Hungary',
              'Iceland',
              'Ireland',
              'Italy',
              'Latvia',
              'Liechtenstein',
              'Lithuania',
              'Luxembourg',
              'Macedonia (FYROM)',
              'Malta',
              'Moldova',
              'Monaco',
              'Netherlands',
              'Norway',
              'Poland',
              'Portugal',
              'Romania',
              'Russia',
              'San Marino',
              'Slovakia',
              'Slovenia',
              'Spain',
              'Sweden',
              'Switzerland',
              'Ukraine',
              'United Kingdom',
            ],
          },
          {
            name: 'North America (22)',
            subcategories: [
              'Antigua and Barbuda',
              'Bahamas',
              'Barbados',
              'Belize',
              'Canada',
              'Costa Rica',
              'Cuba',
              'Dominica',
              'Dominican Republic',
              'El Salvador',
              'Grenada',
              'Guatemala',
              'Haiti',
              'Honduras',
              'Jamaica',
              'Mexico',
              'Nicaragua',
              'Panama',
              'Saint Kitts and Nevis',
              'Saint Vincent and the Grenadines',
              'Trinidad and Tobago',
              'United States',
            ],
          },
          {
            name: 'Oceania (11)',
            subcategories: [
              'Australia',
              'Cook Islands',
              'Fiji',
              'Kiribati',
              'New Zealand',
              'Papua New Guinea',
              'Samoa',
              'Solomon Islands',
              'Timor-Leste',
              'Tonga',
              'Vanuatu',
            ],
          },
          {
            name: 'South America (12)',
            subcategories: [
              'Argentina',
              'Bolivia',
              'Brazil',
              'Chile',
              'Colombia',
              'Ecuador',
              'Guyana',
              'Paraguay',
              'Peru',
              'Suriname',
              'Uruguay',
              'Venezuela',
            ],
          },
        ],
        allowCustom: true,
      },
      {
        filterDisplayName: 'Country(s)',
        filterName: 'country',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'State(s)',
        filterName: 'state',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'City / Cities',
        filterName: 'city',
        filterType: 'multiple_input',
      },
    ],
  },
  {
    categoryName: 'Company',
    filters: [
      {
        filterDisplayName: 'Company Name(s)',
        filterName: 'company_name',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Company Domain(s)',
        filterName: 'company_domain',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Company Size(s)',
        filterName: 'company_size',
        filterType: 'select_input',
        filterOptions: [
          '1-10',
          '11-50',
          '51-100',
          '101-500',
          '501-1000',
          '1001-5000',
          '5001-10000',
          '10001-25000',
          '25000+',
        ],
        allowCustom: true,
      },
      {
        filterDisplayName: 'Company Revenue(s)',
        filterName: 'company_revenue',
        filterType: 'select_input',
        filterOptions: [
          '<1M',
          '1M-10M',
          '10M-50M',
          '50M-100M',
          '100M-500M',
          '500M-1B',
          '1B-5B',
          '5B+',
        ],
        allowCustom: true,
      },
    ],
  },
  {
    categoryName: 'Employment',
    filters: [
      {
        filterDisplayName: 'Current Employer(s)',
        filterName: 'current_employer',
        filterType: 'multiple_input',
      },
      {
        filterDisplayName: 'Previous Employer(s)',
        filterName: 'previous_employer',
        filterType: 'multiple_input',
      },
    ],
  },
];
