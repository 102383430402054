import React, { useState } from 'react';

import styles from './ToolTip.module.css';

const ToolTip = ({ textList, children }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {children}
      {isTooltipVisible && (
        <div className={styles.tooltipBox}>
          {textList.map((text, index) => (
            <div key={index}>
              <p>{text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
