import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

import styles from './PricingPage.module.css';

const PricingPage = () => {
  return (
    <Box className={styles.pricingPageContainer}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Free Plan
          </Typography>
          <Typography color="text.secondary">$0/month</Typography>
          <Typography variant="body2">
            Ideal for individuals just starting out.
            <br />
            - 100 monthly credits
            <br />- Basic features only
          </Typography>
        </CardContent>
        <Button className={styles.priceButton} variant="contained">
          Get Started
        </Button>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Premium Plan
          </Typography>
          <Typography color="text.secondary">$49/month</Typography>
          <Typography variant="body2">
            Perfect for professionals and businesses.
            <br />
            - 1000 monthly credits
            <br />
            - Advanced features
            <br />- Priority support
          </Typography>
        </CardContent>
        <Button
          className={styles.priceButton}
          variant="contained"
          color="primary"
        >
          Subscribe
        </Button>
      </Card>
    </Box>
  );
};

export default PricingPage;
