import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const CustomButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '16px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#1976d2',
    transform: 'scale(1.1)',
  },
});

const WaitlistPage = () => {
  const openWaitlistForm = () => {
    window.open('https://sogc5u07o81.typeform.com/to/YWmrWykO', '_blank');
  };

  return (
    <Box className="default-container">
      <h2>Get Early Access</h2>
      <CustomButton variant="contained" onClick={openWaitlistForm}>
        Start Now
      </CustomButton>
    </Box>
  );
};

export default WaitlistPage;
