import { createSlice } from '@reduxjs/toolkit';

import {
  fetchThreadWithThreadId,
  fetchThreads,
} from '../thunks/ThreadsThunks.js';

export const threadSlice = createSlice({
  name: 'threads',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    unsetThreads: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThreads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchThreads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchThreadWithThreadId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchThreadWithThreadId.fulfilled, (state, action) => {
        state.loading = false;
        const threadIndex = state.data.findIndex(
          (thread) => thread.thread_id === action.payload.thread_id
        );
        if (threadIndex !== -1) {
          state.data[threadIndex] = action.payload;
        } else {
          state.data.push(action.payload);
        }
      })
      .addCase(fetchThreadWithThreadId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { unsetThreads } = threadSlice.actions;

export default threadSlice.reducer;
