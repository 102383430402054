import CookieConsent, { Cookies } from 'react-cookie-consent';

const Consent = () => {
  return (
    <CookieConsent
      onAccept={() => {
        localStorage.setItem('cookieConsent', 'true');
      }}
      onDecline={() => {
        localStorage.setItem('cookieConsent', 'false');
      }}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default Consent;
