import { Box, Button, Grid, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import { fetchThreads } from '../../../redux/thunks/ThreadsThunks.js';
import styles from './DashboardPage.module.css';

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data) || {};
  const threads = useSelector((state) => state.threads.data) || [];
  const isLoading = useSelector((state) => state.threads.loading);
  const [loadingThreadId, setLoadingThreadId] = useState(null);
  const { addNotification } = useNotifications();
  const fetchedThreadData = useRef(false);
  const { uid = '' } = userData;

  useEffect(() => {
    if (uid && !fetchedThreadData.current) {
      fetchedThreadData.current = true;
      dispatch(fetchThreads(uid));
    }
  }, [uid, dispatch]);

  const syncThreads = async () => {
    addNotification({
      message: 'Syncing Threads...',
      type: 'info',
    });
    try {
      await dispatch(fetchThreads(uid));
      addNotification({
        message: 'Successfully synced threads!',
        type: 'success',
      });
    } catch (error) {
      addNotification({
        message: 'Failed to sync threads',
        type: 'error',
      });
    }
  };

  const handleOnClickThread = async (thread) => {
    setLoadingThreadId(thread.thread_id);
    const threadId = thread.thread_id;
    const response = await postMessage('/get_data_for_thread_id', {
      uid,
      threadId,
    });
    if (response.status == 200) {
      navigate(`/a/dashboard/thread/${thread.thread_id}`, {
        state: {
          thread,
          threadData: response.body.threadData,
          messagesData: response.body.messagesData,
        },
      });
      setLoadingThreadId(null);
    } else {
      console.error('Error fetching thread data:', response.body);
      setLoadingThreadId(null);
    }
  };

  const filterThreadsByLabel = (labelId) => {
    return threads.filter((thread) => thread.label_id === labelId);
  };

  const renderThreadsColumn = (labelId, labelName) => (
    <Grid item xs={4}>
      <Paper elevation={3} className={styles.dashboardGridColumn}>
        <h3>{labelName}</h3>
        {filterThreadsByLabel(labelId).map((thread) => (
          <Box
            key={thread.thread_id}
            className={`${styles.dashboardThread} ${thread.thread_id === loadingThreadId ? styles.sweepingAnimation : ''}`}
            onClick={() => handleOnClickThread(thread)}
          >
            {thread.recipient_name}
          </Box>
        ))}
      </Paper>
    </Grid>
  );

  return (
    <Box className="default-container">
      <NavbarApp />
      <Box className={styles.topContainer}>
        <h1> Your Dashboard </h1>
        <Box className={styles.topButtonContainer}>
          <Button onClick={syncThreads} className={styles.topSyncButton}>
            Sync Threads
          </Button>
        </Box>
      </Box>
      {!isLoading ? (
        <Box className={styles.gridContainer}>
          <Grid container spacing={2} className={styles.dashboardGrid}>
            {renderThreadsColumn(0, 'Review')}
            {renderThreadsColumn(1, 'Pending')}
            {renderThreadsColumn(2, 'Follow Up')}
          </Grid>
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

export default DashboardPage;
