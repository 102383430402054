import React, { useEffect, useState } from 'react';

import { searchPlaceholders } from '../../constants/searchPlaceholders.js';
import styles from './Hero.module.css';

const Hero = () => {
  const [index, setIndex] = useState(
    Math.floor(Math.random() * searchPlaceholders.length)
  );
  const [subIndex, setSubIndex] = useState(0);

  useEffect(() => {
    if (subIndex === searchPlaceholders[index].length + 1) {
      const timeout = setTimeout(() => {
        let newIndex;
        do {
          newIndex = Math.floor(Math.random() * searchPlaceholders.length);
        } while (newIndex === index);

        setIndex(newIndex);
        setSubIndex(0);
      }, 2000);
      return () => clearTimeout(timeout);
    }
    const timeout = setTimeout(() => {
      setSubIndex(subIndex + 1);
    }, 50);

    return () => clearTimeout(timeout);
  }, [index, subIndex]);

  return (
    <div className={`${styles.heroWrapper} center`}>
      <div className={`${styles.heroInner}`}>
        <h2 className={styles.headerText}>
          The fastest way to cold email{' '}
          <span style={{ fontWeight: 'bold' }}>anyone</span>
        </h2>
        <div className={styles.slogan}>
          <p>Try searching now...</p>
        </div>
        <div className={styles.inputLocation}>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            placeholder={searchPlaceholders[index].substring(0, subIndex)}
          />
          <i className={`${styles.arrow} fas fa-arrow-right`}></i>
        </div>
      </div>
    </div>
  );
};
export default Hero;
