import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const FallbackPage = () => {
  const navigate = useNavigate();

  return (
    <Box className="default-container">
      <h2>You might've got the wrong link... nothing to see here.</h2>
      <Button className="default-button" onClick={() => navigate('/app')}>
        Return Home
      </Button>
    </Box>
  );
};

export default FallbackPage;
