import { Box, Button, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OpenAILogo from '../../../assets/OpenAILogo.webp';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb.js';
import FilterGroup from '../../../components/Filters/FilterGroup.js';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { supportedFilters } from '../../../constants/supportedFilters.js';
import { removeEmptyListsFromObj } from '../../../core/functions.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import styles from './QueryPage.module.css';

const queryPlaceholder =
  'I want to speak with software engineers at Microsoft working in New York City.';

const QueryPage = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [inputFilters, setInputFilters] = useState({});
  const [currOpenFilter, setCurrOpenFilter] = useState(null);
  const [query, setQuery] = useState('');
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '' } = userData;

  const executeQuery = async () => {
    setInputFilters(removeEmptyListsFromObj(inputFilters));
    if (isLoading || (!query && Object.keys(inputFilters).length === 0)) {
      return;
    }
    const parsedFilters = query ? await parseQueryToFilters() : {};
    const filters = { ...parsedFilters, ...inputFilters };
    if (Object.keys(filters).length === 0) {
      setIsLoading(false);
      addNotification({
        message:
          'Please enter a different query, or manually input your filters.',
        type: 'error',
      });
      return;
    }
    setIsLoading(true);
    const executePayload = { uid, filters };
    const response = await postMessage('execute_query', executePayload);
    if (response.status == 200) {
      const pagination = response.body.pagination;
      const profiles = response.body.profiles;
      setIsLoading(false);
      navigate('/a/select', { state: { filters, pagination, profiles } });
    } else {
      if (response.body.errorType == 'noneFoundError') {
        addNotification({
          message: 'No profiles found. Try broadening your search.',
          type: 'error',
        });
      } else if (response.body.errorType == 'apiError') {
        addNotification({
          message: 'Server error executing query. Please try again later.',
          type: 'error',
        });
      } else {
        addNotification({
          message:
            'Error executing query with Unknown Error. Please try again.',
          type: 'error',
        });
      }
      setIsLoading(false);
    }
  };

  const parseQueryToFilters = async () => {
    setIsLoading(true);
    const parsePayload = { uid, query };
    const response = await postMessage('parse_query', parsePayload);
    if (response.status === 200) {
      const parsedFilters = response.body;
      return parsedFilters;
    } else {
      setIsLoading(false);
      addNotification({
        message: 'Error parsing query. Please try again.',
        type: 'error',
      });
      console.error('Error:', error);
    }
  };

  return (
    <Box className="default-container">
      <NavbarApp />
      <Breadcrumb
        steps={['Query', 'Select', 'Template', 'Review']}
        activeStep={0}
      />
      {!isLoading && (
        <>
          <h1 className={styles.titleContainer}>
            The fastest way to cold email — start searching now.
          </h1>

          <Box className={styles.queryContainer}>
            <Box className={styles.toggleFilterButtonContainer}>
              <Button
                variant="contained"
                onClick={() => setFiltersVisible(!filtersVisible)}
                className={styles.toggleFilterButton}
              >
                Filters
              </Button>
            </Box>

            <TextField
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  executeQuery();
                }
              }}
              placeholder={queryPlaceholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      onClick={executeQuery}
                      disabled={
                        isLoading ||
                        (!query && Object.keys(inputFilters).length === 0)
                      }
                    >
                      Search{' '}
                      <i className={`${styles.arrow} fas fa-arrow-right`}></i>
                    </Button>
                  </InputAdornment>
                ),
                style: {
                  fontFamily: 'Poppins, sans-serif',
                },
              }}
              disabled={isLoading}
              className={`default-textfield ${styles.queryTextField}`}
            />
          </Box>

          <Box
            sx={{
              columnCount: 2,
              '& > *': {
                breakInside: 'avoid',
              },
            }}
            className={styles.filterContainer}
          >
            {filtersVisible &&
              supportedFilters.map((filterGroup) => (
                <FilterGroup
                  key={filterGroup.categoryName}
                  filterGroup={filterGroup}
                  setFilters={setInputFilters}
                  currFilters={inputFilters}
                  restrictOneOpenFilter={true}
                  currOpenFilter={currOpenFilter}
                  setCurrOpenFilter={setCurrOpenFilter}
                />
              ))}
          </Box>
          <div className={styles.poweredByContainer}>
            <img src={OpenAILogo} alt="OpenAI Logo" />
          </div>
        </>
      )}

      {isLoading && (
        <LoadingIndicator loadingText="Finding the best candidates for you..." />
      )}
    </Box>
  );
};

export default QueryPage;
