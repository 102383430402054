import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';

import styles from './Filters.module.css';

const SelectInputFilter = ({
  filterDisplayName,
  filterName,
  filterOptions,
  allowCustom,
  sync,
  init,
  collapse,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  const [filterValue, setFilterValue] = useState(init[filterName] || []);
  const [excludeFilterValue, setExcludeFilterValue] = useState(
    init[`exclude_${filterName}`] || []
  );
  const [customValue, setCustomValue] = useState('');
  const [filterOpen, setFilterOpen] = useState(
    restrictOneOpenFilter ? currOpenFilter === filterName : !collapse
  );

  useEffect(() => {
    if (restrictOneOpenFilter) {
      setFilterOpen(currOpenFilter === filterName);
    }
  }, [currOpenFilter, filterName, restrictOneOpenFilter]);

  const handleSelectChange = (value) => {
    setFilterValue(value);
    sync((prev) => ({
      ...prev,
      [filterName]: value,
      [`exclude_${filterName}`]: excludeFilterValue,
    }));
  };

  const handleFilterRemove = (value, isExcluded = false) => {
    if (isExcluded) {
      const newValue = excludeFilterValue.filter((item) => item !== value);
      setExcludeFilterValue(newValue);
      sync((prev) => ({
        ...prev,
        [filterName]: filterValue,
        [`exclude_${filterName}`]: newValue,
      }));
    } else {
      const newValue = filterValue.filter((item) => item !== value);
      setFilterValue(newValue);
      sync((prev) => ({
        ...prev,
        [filterName]: newValue,
        [`exclude_${filterName}`]: excludeFilterValue,
      }));
    }
  };

  const handleInvertFilter = (value) => {
    let newFilterValue, newExcludeFilterValue;
    if (excludeFilterValue.includes(value)) {
      newExcludeFilterValue = excludeFilterValue.filter((v) => v !== value);
      newFilterValue = [...filterValue, value];
    } else {
      newFilterValue = filterValue.filter((v) => v !== value);
      newExcludeFilterValue = [...excludeFilterValue, value];
    }
    setFilterValue(newFilterValue);
    setExcludeFilterValue(newExcludeFilterValue);
    sync((prev) => ({
      ...prev,
      [filterName]: newFilterValue,
      [`exclude_${filterName}`]: newExcludeFilterValue,
    }));
  };

  const handleCustomValueEnter = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      const newValues = [...filterValue, event.target.value];
      setFilterValue(newValues);
      setCustomValue('');
      sync((prev) => ({
        ...prev,
        [filterName]: newValues,
        [`exclude_${filterName}`]: excludeFilterValue,
      }));
    }
  };

  const toggleFilterOpen = () => {
    if (restrictOneOpenFilter) {
      if (currOpenFilter === filterName) {
        setCurrOpenFilter('');
      } else {
        setCurrOpenFilter(filterName);
      }
    } else {
      setFilterOpen(!filterOpen);
    }
  };

  return (
    <Box className={styles.filterContainer}>
      <Box className={styles.filterInputContainer}>
        <Typography gutterBottom>{filterDisplayName}</Typography>
        {collapse &&
          (filterOpen ? (
            <ExpandMoreIcon onClick={toggleFilterOpen} />
          ) : (
            <ChevronRightIcon onClick={toggleFilterOpen} />
          ))}
      </Box>
      {filterOpen ? (
        <Box className={styles.filterSelectContainer}>
          <Select
            multiple
            value={filterValue}
            onChange={(e) => handleSelectChange(e.target.value)}
            input={<OutlinedInput label={filterDisplayName} />}
            renderValue={(selected) => `${selected.length} Item(s) Selected.`}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 180,
                  width: '20%',
                },
              },
            }}
            sx={{ width: allowCustom ? '20%' : '100%' }}
          >
            {filterOptions.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {allowCustom && (
            <TextField
              value={customValue}
              placeholder="Custom Value"
              onChange={(event) => setCustomValue(event.target.value)}
              onKeyDown={handleCustomValueEnter}
              variant="outlined"
              className={styles.filterSelectCustomInput}
            />
          )}
        </Box>
      ) : (
        <div />
      )}
      <Box className={styles.filterChipContainer}>
        {filterValue.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => handleFilterRemove(value)}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: green[500], margin: 2 }}
            onClick={() => handleInvertFilter(value)}
          />
        ))}
        {excludeFilterValue.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => handleFilterRemove(value, true)}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: red[500], margin: 2 }}
            onClick={() => handleInvertFilter(value)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectInputFilter;
