import { Box, Button, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GoogleLogo from '../../../assets/GoogleLogo.webp';
import MicrosoftLogo from '../../../assets/MicrosoftLogo.webp';
import { firebase } from '../../../core/firebase.js';
import { postMessage } from '../../../core/server.js';
import styles from './SigninPage.module.css';

const SignInPage = () => {
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const newPasswordInputRef = useRef(null);

  const navToPassword = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      passwordInputRef.current.focus();
    }
  };

  const navToNewPassword = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      newPasswordInputRef.current.focus();
    }
  };

  const handlePasswordKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickSignIn('email');
    }
  };

  const handleNewPasswordKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickEmailSignUp();
    }
  };

  const onClickEmailSignUp = async () => {
    try {
      await firebase.createUserWithEmail(newUserEmail, newUserPassword);
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  const onClickSignIn = async (signInMethod) => {
    setLoading(true);
    try {
      if (signInMethod === 'google') {
        await firebase.signInWithGoogle();
      } else if (signInMethod === 'microsoft') {
        await firebase.signInWithMicrosoft();
      } else if (signInMethod === 'email') {
        await firebase.signInWithEmail(userEmail, userPassword);
      }
    } catch (error) {
      console.error('Error signing in:', error);
      setLoading(false);
    }
    const response = await postMessage('/try_create_user', {
      uid: firebase.currentUser.uid,
      email: firebase.currentUser.email,
    });
    if (response.status == 200) {
      navigate('/a/query');
    } else {
      console.error('Error creating user:', response.body.error);
    }
  };

  return (
    <Box className="default-container">
      <div className={styles.generalcontainer}>
        <div className={styles.sectioncontainer}>
          <h3> Log In </h3>
          <div className={styles.buttonWrapper}>
            <Button
              className={`greyButton ${styles.signInButton}`}
              onClick={() => onClickSignIn('google')}
              disabled={loading}
              startIcon={
                <img
                  src={GoogleLogo}
                  alt="Google logo"
                  style={{ width: '20px', height: '20px' }}
                />
              }
            >
              Log in with Google
            </Button>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={`greyButton ${styles.signInButton}`}
              onClick={() => onClickSignIn('microsoft')}
              disabled={loading}
              startIcon={
                <img
                  src={MicrosoftLogo}
                  alt="Microsoft logo"
                  style={{ width: '20px', height: '20px' }}
                />
              }
            >
              Log in with Microsoft
            </Button>
          </div>

          <h2>OR</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '15px',
            }}
          >
            <TextField
              value={userEmail}
              onChange={(event) => setUserEmail(event.target.value)}
              onKeyDown={navToPassword}
              variant="outlined"
              label="Email"
            />
            <TextField
              inputRef={passwordInputRef}
              value={userPassword}
              onChange={(event) => setUserPassword(event.target.value)}
              onKeyDown={handlePasswordKeyDown}
              variant="outlined"
              type="password"
              label="Password"
            />
            <div className={styles.emailButtonWrapper}>
              <Button
                className={`greyButton ${styles.signInButton}`}
                onClick={() => onClickSignIn('email')}
                disabled={loading || !userEmail || !userPassword}
              >
                Log In with Email
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.sectioncontainer}>
          <h3>Sign Up</h3>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '15px',
            }}
          >
            <TextField
              value={newUserEmail}
              onChange={(event) => setNewUserEmail(event.target.value)}
              onKeyDown={navToNewPassword}
              variant="outlined"
              label="New Email"
            />
            <TextField
              inputRef={newPasswordInputRef}
              value={newUserPassword}
              onChange={(event) => setNewUserPassword(event.target.value)}
              onKeyDown={handleNewPasswordKeyDown}
              variant="outlined"
              type="password"
              label="New Password"
            />

            <div className={styles.buttonWrapper}>
              <Button
                className={`greyButton ${styles.signInButton}`}
                onClick={() => onClickEmailSignUp()}
                disabled={loading || !newUserEmail || !newUserPassword}
              >
                Sign up with Email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <h3>Or, join our waitlist to get early access:</h3>
      <div className={styles.buttonWrapper}>
        <Button
          className={`greyButton ${styles.signInButton}`}
          onClick={() => navigate('/waitlist')}
          disabled={loading}
        >
          Join Our Waitlist
        </Button>
      </div>
    </Box>
  );
};

export default SignInPage;
