import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb.js';
import EditorToolbar, {
  formats,
  modules,
} from '../../../components/EditorToolbar/EditorToolbar.js';
import FileUploadButton from '../../../components/FileUploadButton/FileUploadButton.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { defaultTemplates } from '../../../constants/defaultTemplates.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import { fetchTemplates } from '../../../redux/thunks/TemplatesThunks.js';
import styles from './TemplatePage.module.css';

const templateNamePlaceholder = '(Optional) Enter a name for your template.';
const emailSubjectTemplatePlaceHolder = 'Paste your email subject here.';
const emailBodyTemplatePlaceHolder = 'Paste your email body template here.';
const userBackgroundPlaceholders = [
  "I'm a student at Stanford University looking for a summer internship in finance.",
  "I'm a student at Stanford University looking to research popular DevOp tools.",
];
const actionItemPlaceholders = [
  'I hope to schedule a thirty-minute call via Calendly.',
  'I hope to build meaningful connections and find mentorship',
];

const TemplatePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const selectedProfiles = location.state?.selectedProfiles;
  // User intent for draft generation.
  const [userBackground, setUserBackground] = useState('');
  const [actionItem, setActionItem] = useState('');
  // Template object.
  const [templateName, setTemplateName] = useState('');
  const [emailSubjectTemplate, setEmailSubjectTemplate] = useState('');
  const [emailBodyTemplate, setEmailBodyTemplate] = useState('');
  const [isSaveChecked, setIsSaveChecked] = useState(false);
  // Draft checks.
  const [hasUnhandledBrackets, setHasUnhandledBrackets] = useState(false);
  const [hasInvalidCurlyBraces, setHasInvalidCurlyBraces] = useState(false);
  // Other state variables.
  const [loadAnchorEl, setLoadAnchorEl] = useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const loadTemplateOpen = Boolean(loadAnchorEl);
  const deleteTemplateOpen = Boolean(deleteAnchorEl);
  const [showTemplateBox, setShowTemplateBox] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  // Redux States
  const templates = useSelector((state) => state.templates.data) || [];
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '' } = userData;

  useEffect(() => {
    const { hasUnhandledBrackets, hasInvalidCurlyBraces } =
      checkTextConditions(emailBodyTemplate);
    setHasUnhandledBrackets(hasUnhandledBrackets);
    setHasInvalidCurlyBraces(hasInvalidCurlyBraces);
  }, [emailBodyTemplate]);

  const handleLoadTemplateClick = (event) => {
    setLoadAnchorEl(event.currentTarget);
  };

  const handleLoadTemplateClose = () => {
    setLoadAnchorEl(null);
  };

  const handleDeleteTemplateClick = (event) => {
    setDeleteAnchorEl(event.currentTarget);
  };

  const handleDeleteTemplateClose = () => {
    setDeleteAnchorEl(null);
  };

  const handleSelectTemplate = (template) => {
    setTemplateName(template.name);
    setEmailSubjectTemplate(template.subject);
    setEmailBodyTemplate(template.body);
    handleLoadTemplateClose();
  };

  const handleDeleteTemplate = (templateName) => {
    tryDeleteTemplate(templateName);
    handleDeleteTemplateClose();
  };

  const handleDefaultTemplateClick = (template) => {
    setTemplateName(template.templateName);
    setEmailSubjectTemplate(template.subject);
    setEmailBodyTemplate(template.body);
  };

  const trySaveTemplate = async () => {
    const jsonPayload = {
      uid,
      templateName,
      emailSubjectTemplate,
      emailBodyTemplate,
    };

    try {
      const response = await postMessage('save_template_for_uid', jsonPayload);
      if (response.status === 200) {
        addNotification({
          message: 'Template Saved Successfully.',
          type: 'success',
        });
        dispatch(fetchTemplates(uid));
      } else {
        addNotification({
          message: 'Error saving template. Please try again.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const tryDeleteTemplate = async (templateName) => {
    const jsonPayload = { uid, templateName };
    try {
      const response = await postMessage(
        'delete_template_for_uid',
        jsonPayload
      );
      if (response.status === 200) {
        addNotification({
          message: 'Template Deleted Successfully.',
          type: 'success',
        });
      } else {
        addNotification({
          message: 'Error deleting template. Please try again.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(fetchTemplates(uid));
  };

  const handleContinueClicked = () => {
    if (isSaveChecked) {
      if (!templateName) {
        addNotification({
          message: 'Please enter a name for your template before continuing.',
          type: 'error',
        });
        return;
      }
      trySaveTemplate(emailSubjectTemplate);
    }
    navigate('/a/review', {
      state: {
        emailSubjectTemplate,
        emailBodyTemplate,
        selectedProfiles,
        uploadedFile: { file, fileName },
      },
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size <= 5242880) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setError('');
      } else {
        setFile(null);
        setFileName('');
        setError('File size should not exceed 5MB.');
      }
    }
  };

  const handleRemoveAttachment = () => {
    setFile(null);
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const generateTemplateFromIntent = async () => {
    const jsonPayload = { uid, userBackground, actionItem };
    setIsLoading(true);
    try {
      const response = await postMessage('generate_draft', jsonPayload);
      if (response.status === 200) {
        setEmailSubjectTemplate(response.body.subject_line);
        setEmailBodyTemplate(response.body.email_body);
        setShowTemplateBox(!showTemplateBox);
      } else {
        addNotification({
          message: 'Error generating template. Please try again.',
          type: 'error',
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  const checkTextConditions = (text) => {
    let hasUnhandledBrackets = false;
    let hasInvalidCurlyBraces = false;

    if (text.match(/\[[^\]]*\]/)) {
      hasUnhandledBrackets = true;
    }
    const curlyBracesMatches = text.match(/\{\{\s*(.*?)\s*\}\}/g) || [];
    curlyBracesMatches.forEach((match) => {
      const inside = match.match(/\{\{\s*(.*?)\s*\}\}/)[1];
      if (
        inside !== 'Recipient Name' &&
        inside !== 'Recipient FirstName' &&
        inside !== 'Recipient LastName' &&
        inside !== 'Company Name' &&
        inside !== 'Your Name'
      ) {
        hasInvalidCurlyBraces = true;
      }
    });

    return { hasUnhandledBrackets, hasInvalidCurlyBraces };
  };

  const parseAndStyleText = (text) => {
    let escapedText = text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');

    escapedText = escapedText.replace(
      /\[\s*(.*?)\s*\]/g,
      '<span style="color: orange;">[$1]</span>'
    );
    escapedText = escapedText.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) => {
      if (
        p1.trim() === 'Recipient Name' ||
        p1.trim() === 'Recipient FirstName' ||
        p1.trim() === 'Recipient LastName' ||
        p1.trim() === 'Company Name' ||
        p1.trim() === 'Your Name'
      ) {
        return `<span style="color: green;">{{${p1}}}</span>`;
      } else {
        return `<span style="color: red;">{{${p1}}}</span>`;
      }
    });

    return { __html: escapedText };
  };

  return (
    <Box className="default-container">
      <NavbarApp />
      <Breadcrumb
        steps={['Query', 'Select', 'Template', 'Review']}
        activeStep={2}
      />
      {showTemplateBox && (
        <>
          <div className={styles.templateInfoContainer}>
            <div className={styles.upperLine}>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                Paste in your email template below or &nbsp;
                <Button
                  onClick={() => setShowTemplateBox(!showTemplateBox)}
                  className="greyButton"
                >
                  generate a template with AI
                </Button>
              </Box>

              <p className={styles.instructionText}>
                We support Jinja2 templating, so you can use the following
                variables:
                {'{{ Recipient Name }}'}, {'{{ Company Name }}'}
              </p>
            </div>
            <div className={styles.templateInputContainer}>
              <div className={styles.rowPiece}>
                <div className={styles.columnPiece}>
                  <TextField
                    type="text"
                    multiline
                    rows={1}
                    variant="outlined"
                    className="default-textfield"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    placeholder={templateNamePlaceholder}
                    InputProps={{
                      style: {
                        fontSize: '.7rem',
                      },
                    }}
                    style={{
                      width: '100%',
                      maxWidth: '1500px',
                      marginBottom: '20px',
                    }}
                  />
                  <TextField
                    type="text"
                    multiline
                    rows={1}
                    variant="outlined"
                    className="default-textfield"
                    value={emailSubjectTemplate}
                    onChange={(e) => setEmailSubjectTemplate(e.target.value)}
                    placeholder={emailSubjectTemplatePlaceHolder}
                    InputProps={{
                      style: {
                        fontSize: '.7rem',
                      },
                    }}
                    style={{
                      width: '100%',
                      maxWidth: '1500px',
                    }}
                  />

                  <ReactQuill
                    theme="snow"
                    value={emailBodyTemplate}
                    onChange={setEmailBodyTemplate}
                    placeholder={emailBodyTemplatePlaceHolder}
                    InputProps={{
                      style: {
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '.7rem',
                        padding: '5px',
                      },
                    }}
                    style={{
                      borderTop: '1px solid #d9d9d9',
                      height: '400px',
                    }}
                    modules={modules}
                    formats={formats}
                  />
                  <EditorToolbar />
                  {/* See if there's a way to decompose out this component into FileInput */}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <FileUploadButton
                      uploadRef={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <Button onClick={handleRemoveAttachment}> Remove </Button>
                    {file && <p>{fileName}</p>}
                  </Box>
                  <Box>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <p>
                      Files you attach here will not be saved with your
                      template.
                    </p>
                  </Box>
                  {hasUnhandledBrackets && (
                    <p style={{ color: 'orange' }}>
                      You have unhandled brackets in your email body template.
                    </p>
                  )}
                  {hasInvalidCurlyBraces && (
                    <p style={{ color: 'red' }}>
                      You have invalid curly braces in your email body template.
                    </p>
                  )}
                  <div className={styles.continueContainer}>
                    <Button
                      onClick={handleContinueClicked}
                      style={{ float: 'right' }}
                      disabled={error}
                    >
                      Continue
                    </Button>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={isSaveChecked}
                          onChange={(event) =>
                            setIsSaveChecked(event.target.checked)
                          }
                          style={{ marginRight: '5px' }}
                        />
                        Save Current Template
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'space-between',
                    }}
                  >
                    <div className="columnPiece">
                      <Button
                        className="greyButton"
                        onClick={handleLoadTemplateClick}
                      >
                        Load Template
                      </Button>
                      <Menu
                        anchorEl={loadAnchorEl}
                        open={loadTemplateOpen}
                        onClose={handleLoadTemplateClose}
                      >
                        {templates.map((template, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleSelectTemplate(template)}
                          >
                            {template.name}
                          </MenuItem>
                        ))}
                      </Menu>
                      <div style={{ marginTop: '5vw' }}>
                        {' '}
                        <p>Default Templates</p>
                        {defaultTemplates.map((template, index) => (
                          <Button
                            onClick={() => handleDefaultTemplateClick(template)}
                            className={`greyButton ${styles.fullWidthButton}`}
                            key={index}
                          >
                            {template.templateName}
                          </Button>
                        ))}
                      </div>
                    </div>
                    <div className={styles.deleteTemplateContainer}>
                      <Button
                        className="greyButton"
                        onClick={handleDeleteTemplateClick}
                      >
                        Delete Template
                      </Button>
                      <Menu
                        anchorEl={deleteAnchorEl}
                        open={deleteTemplateOpen}
                        onClose={handleDeleteTemplateClose}
                      >
                        {templates.map((template, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleDeleteTemplate(template.name)}
                          >
                            {template.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <div className={styles.templateConfigContainer}></div>
          </div>
        </>
      )}

      {!showTemplateBox && (
        <Box className="default-container">
          <h2>Let's help you generate the best drafts possible.</h2>
          <h3>
            Tell us a bit about yourself, and what you're looking to reach out
            about
          </h3>
          <TextField
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="default-textfield"
            value={userBackground}
            onChange={(e) => setUserBackground(e.target.value)}
            placeholder={userBackgroundPlaceholders[0]}
            disabled={isLoading}
            InputProps={{
              style: {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: '20px',
                padding: '5px',
              },
            }}
            style={{
              width: '100%',
              maxWidth: '1500px',
            }}
          />
          <h3>What do you hope to get out of your outreach?</h3>
          <TextField
            type="text"
            multiline
            rows={4}
            variant="outlined"
            className="default-textfield"
            value={actionItem}
            onChange={(e) => setActionItem(e.target.value)}
            placeholder={actionItemPlaceholders[0]}
            disabled={isLoading}
            InputProps={{
              style: {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
                fontSize: '20px',
                padding: '5px',
              },
            }}
            style={{
              width: '100%',
              maxWidth: '1500px',
            }}
          />
          <Box sx={{ marginTop: '50px' }}>
            <Button
              disabled={!userBackground || !actionItem}
              onClick={generateTemplateFromIntent}
              className="default-button"
            >
              Generate Template
            </Button>
            <Button
              onClick={() => setShowTemplateBox(!showTemplateBox)}
              className="default-button"
            >
              I already have a template
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TemplatePage;
