import React, { useState } from 'react';

import Consent from '../../../components/Consent/Consent';
import Footer from '../../../components/Footer/Footer';
import Hero from '../../../components/Hero/Hero';
import NavbarResponsive from '../../../components/Navbar/NavbarResponsive';
import NavbarStatic from '../../../components/Navbar/NavbarStatic';
import Pricing from '../../../components/Pricing/Pricing';
import Product from '../../../components/Product/Product';
import Questions from '../../../components/Questions/Questions';
import styles from './LandingPage.module.css';

const LandingPage = () => {
  const [hamActive, setHamActive] = useState(false);

  const planFunctions = {
    Spark: () => console.log('Spark'),
    Flame: () => console.log('Flame'),
    Inferno: () => console.log('Inferno'),
  };

  return (
    <div className={styles.landingAppContainer}>
      <NavbarStatic hamActive={hamActive} setHamActive={setHamActive} />
      <NavbarResponsive hamActive={hamActive} />
      <Consent />
      <Hero />
      <Product />
      <Pricing planFunctions={planFunctions} />
      <Questions />
      <Footer />
    </div>
  );
};

export default LandingPage;
