import { Box, CircularProgress } from '@mui/material';
import React from 'react';

import styles from './LoadingIndicator.module.css';

const LoadingIndicator = ({ loadingText = 'Loading...' }) => {
  return (
    <div className={styles.parent}>
      <h2 className={styles.progressText}>{loadingText}</h2>
      <Box className={styles.progress}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default LoadingIndicator;
