import React from 'react';

import styles from './Footer.module.css';

const Footer = () => {
  const handleTermsClick = () => {
    window.open(
      'https://wool-crepe-4d8.notion.site/Terms-of-Use-3b35d00acc0045b0aa4b49a3e135d856?pvs=4',
      '_blank'
    );
  };

  const handlePolicyClick = () => {
    window.open(
      'https://wool-crepe-4d8.notion.site/PRIVACY-POLICY-4c80797bb86340d68244c7f2f39aa57c?pvs=4',
      '_blank'
    );
  };

  const handleCookieClick = () => {
    window.open(
      'https://wool-crepe-4d8.notion.site/Cookies-Policy-d49d63c9ffe642929b91a87e648218ae?pvs=4',
      '_blank'
    );
  };

  return (
    <nav className={`${styles.navbarWrapper} center`}>
      <div className={`${styles.navbarColumn} center`}>
        <div className={`${styles.navbarInner} center`}>
          <div className={`${styles.navLeft}`}>Legal</div>
          <div className="center">
            <a
              href="#"
              className={`${styles.navLink}`}
              onClick={handleTermsClick}
            >
              Terms of use
            </a>
            <a
              href="#"
              className={`${styles.navLink}`}
              onClick={handlePolicyClick}
            >
              Privacy policy
            </a>
            <a
              href="#"
              className={`${styles.navLink}`}
              onClick={handleCookieClick}
            >
              Cookies policy
            </a>
          </div>
          <div>
            {/* <span>
              <a href="#facebook" className="fab fa-facebook-square">
                {" "}
              </a>
            </span> */}
            {/* <span>
              <a href="#linkedin" className={`fab fa-twitter`}>
                {" "}
              </a>
            </span> */}
            {/* <span>
              <a
                href="#instagram"
                className="fab fa-instagram"
                style={{ color: "black" }}
              >
                {" "}
              </a>
            </span> */}
          </div>
        </div>
        <div className={`${styles.footerText} center`}>
          <p>©2024 fireoff.ai. All rights reserved.</p>
        </div>
      </div>
    </nav>
  );
};
export default Footer;
