import { createAsyncThunk } from '@reduxjs/toolkit';

import { getMessage } from '../../core/server';

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async (uid, thunkAPI) => {
    try {
      const response = await getMessage('get_user_templates', { uid });
      return response.body;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
