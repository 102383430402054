import config from './config.js';

const postMessage = async (route, data) => {
  try {
    const response = await fetch(`${config.backendHost}/${route}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    const responseBody = await response.json();
    return {
      status: response.status,
      body: responseBody,
    };
  } catch (error) {
    console.error('Network Fault:', error);
    throw error;
  }
};

const getMessage = async (route, params = {}) => {
  try {
    const queryParams = new URLSearchParams(params);
    const url = `${config.backendHost}/${route}?${queryParams}`;
    const response = await fetch(url, { method: 'GET' });
    const responseBody = await response.json();
    return {
      status: response.status,
      body: responseBody,
    };
  } catch (error) {
    console.error('Network Fault:', error);
    throw error;
  }
};

export { postMessage, getMessage };
