import { Button } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './SideBar.module.css';

const AccountSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isPathActive = (path) => location.pathname === path;

  return (
    <div className={styles.sideBarContainer}>
      <Button
        className={`greyButton ${styles.sideBarButton}`}
        onClick={() => navigate('/a/account')}
        disabled={isPathActive('/a/account')}
      >
        Account
      </Button>
      <Button
        onClick={() => navigate('/a/plan')}
        className={`greyButton ${styles.sideBarButton}`}
        disabled={isPathActive('/a/plan')}
      >
        My Plan
      </Button>
    </div>
  );
};

export default AccountSideBar;
