export const productFeatures = [
  {
    field: '500 million contacts',
    description: 'available in our bank ready for you to contact.',
  },
  {
    field: 'Advanced Filters',
    description: "to find exactly who you're looking for.",
  },
  {
    field: 'Custom AI Tools',
    description: 'to improve your email open rates & readability.',
  },
  {
    field: 'Tracking Dashboard',
    description: 'to assess the status of all your sent emails.',
  },
];
