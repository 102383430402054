import { Box, Button, List, ListItem, ListItemButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { decodeBase64, trimStringToNumChars } from '../../../core/functions.js';
import { isPastDate } from '../../../core/functions.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import { fetchThreadWithThreadId } from '../../../redux/thunks/ThreadsThunks.js';
import styles from './ThreadPage.module.css';

const ThreadPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { threadId } = useParams();
  const { addNotification } = useNotifications();
  const [threadData, setThreadData] = useState(
    location.state?.threadData || null
  );
  console.log(threadData);
  const [messagesData, setMessagesData] = useState(
    location.state?.messagesData || {}
  );
  console.log(messagesData);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(
    threadData.messages.length - 1
  );
  console.log(selectedMessageIndex);
  const threadMetaData = location.state?.thread || [];
  const recipientName = location.state?.thread.recipient_name;
  const recipientEmail = location.state?.thread.recipient_email;
  const [isLoading, setIsLoading] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().add(60, 'minute'));
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '', email = '' } = userData;

  const loadThread = async () => {
    if (threadId) {
      setIsLoading(true);
      setIsMessagesLoading(true);
      const response = await postMessage('/get_data_for_thread_id', {
        uid,
        threadId,
      });
      if (response.status == 200) {
        setThreadData(response.body.threadData);
        setMessagesData(response.body.messagesData);
        setSelectedMessageIndex(response.body.threadData.messages.length - 1);
        setIsLoading(false);
      } else {
        console.error('Error fetching thread data:', response.body);
        setThreadData(null);
      }
    }
    dispatch(fetchThreadWithThreadId({ uid, threadId }));
    setIsLoading(false);
    setIsMessagesLoading(false);
  };

  const sendCurrentDraft = async () => {
    const draftMessageId =
      threadMetaData.emails_metadata[selectedMessageIndex].email_id;
    const response = await postMessage('/send_draft', {
      uid,
      draftMessageId,
    });
    if (response.status == 200) {
      addNotification({
        message: 'Draft sent successfully!',
        type: 'success',
      });
    } else {
      addNotification({
        message: 'Error sending draft.',
        type: 'error',
      });
    }
    dispatch(fetchThreadWithThreadId({ uid, threadId }));
    await loadThread();
  };

  const scheduleSendCurrentDraft = async () => {
    if (selectedDate.isBefore(dayjs())) {
      addNotification({
        message: 'Cannot schedule a draft in the past.',
        type: 'error',
      });
      setSelectedDate(dayjs());
      return;
    }
    const draftMessageId =
      threadMetaData.emails_metadata[selectedMessageIndex].email_id;
    const response = await postMessage('/schedule_send_draft', {
      uid,
      draftMessageId,
      scheduledTime: selectedDate.toISOString(),
    });
    if (response.status == 200) {
      addNotification({
        message: 'Draft scheduled successfully!',
        type: 'success',
      });
    } else {
      addNotification({
        message: 'Error scheduling draft.',
        type: 'error',
      });
    }
  };

  const findHeaderValue = (headers, name) => {
    const header = headers.find(
      (h) => h.name.toLowerCase() === name.toLowerCase()
    );
    return header ? header.value : null;
  };

  const getEmailContent = (message) => {
    if (message.payload.body && message.payload.body.data) {
      return decodeBase64(message.payload.body.data);
    }

    if (message.payload.parts && message.payload.parts.length) {
      const parts = message.payload.parts;

      for (const part of parts) {
        if (part.mimeType === 'text/plain' || part.mimeType === 'text/html') {
          if (part.body && part.body.data) {
            return decodeBase64(part.body.data);
          }
        }
        if (part.parts) {
          return getEmailContent({ payload: { parts: part.parts } });
        }
      }
    }
    return 'No content available';
  };

  if (isLoading) {
    return (
      <Box className="default-container">
        <NavbarApp />
        <LoadingIndicator />
      </Box>
    );
  }

  const renderTopContainer = () => {
    return (
      <div className={styles.topContainer}>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
        <h1>
          Your Conversation with {recipientName} ({recipientEmail})
        </h1>
        <Button onClick={loadThread}>Sync</Button>
      </div>
    );
  };

  const renderThreadHistory = () => {
    return (
      <Box className={styles.threadHistoryContainer}>
        <List className={styles.threadHistoryList}>
          {threadData.messages.map((message, index) => {
            const headers = message.payload.headers;
            const date = new Date(parseInt(message.internalDate, 10));
            const dateString = date.toLocaleString();
            const subject = findHeaderValue(headers, 'Subject');
            const sendEmail = findHeaderValue(headers, 'From');
            const receiveEmail = findHeaderValue(headers, 'To');
            const sendName = sendEmail.includes(email)
              ? 'You'
              : sendEmail.includes(recipientEmail)
                ? recipientName
                : sendEmail;
            const receiveName = receiveEmail.includes(email)
              ? 'You'
              : receiveEmail.includes(recipientEmail)
                ? recipientName
                : receiveEmail;

            return (
              <ListItem
                key={index}
                disablePadding
                className={
                  selectedMessageIndex && index === selectedMessageIndex
                    ? styles.selectedListContainer
                    : styles.unselectedListContainer
                }
              >
                <ListItemButton onClick={() => setSelectedMessageIndex(index)}>
                  <div className={styles.listMessagePreviewContainer}>
                    <h3>
                      {sendName}
                      {' -> '}
                      {receiveName}
                    </h3>
                    <p>Subject: {trimStringToNumChars(subject)}</p>
                    <p>Created: {dateString}</p>
                    {message.labelIds.includes('DRAFT') && <p>Draft</p>}
                  </div>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderCurrentThread = () => {
    if (isMessagesLoading || selectedMessageIndex === null) {
      return (
        <div className={styles.currentThreadContainer}>
          <LoadingIndicator loadingText="Loading Message Contents..." />
        </div>
      );
    }

    const selectedMessage =
      messagesData[threadData.messages[selectedMessageIndex]['id']];
    const emailContent = getEmailContent(selectedMessage);
    const selectedMessageHeaders = selectedMessage.payload.headers;

    if (selectedMessage.labelIds.includes('SENT')) {
      return (
        <div className={styles.currentThreadContainer}>
          <div className={styles.messageViewContainer}>
            <p className={styles.messagePreLine}>
              {emailContent ? emailContent : 'No Message.'}
            </p>
          </div>
          <Button onClick={() => console.log('Implement Forward')}>
            <i className="fas fa-share"></i>
            <p className={styles.messageButtonText}> Forward Email </p>
          </Button>
        </div>
      );
    }

    if (selectedMessage.labelIds.includes('DRAFT')) {
      return (
        <div className={styles.currentThreadContainer}>
          <div className={styles.messageViewContainer}>
            <p className={styles.messagePreLine}>{emailContent}</p>
          </div>
          <div className={styles.draftButtonContainer}>
            <Button onClick={sendCurrentDraft}>
              <i className="fas fa-paper-plane"></i>
              <p className={styles.messageButtonText}> Send Draft </p>
            </Button>
            <div className={styles.draftScheduleButtonContainer}>
              <Button onClick={scheduleSendCurrentDraft}>Schedule Send</Button>
              <Box className={styles.dateTimePickerContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Select Date and Time"
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    minDateTime={dayjs().add(60, 'minute')}
                    maxDateTime={dayjs().add(2, 'week')}
                    views={['year', 'month', 'day', 'hours']}
                    shouldDisableDate={isPastDate}
                  />
                </LocalizationProvider>
              </Box>
            </div>
          </div>
        </div>
      );
    }

    if (findHeaderValue(selectedMessageHeaders, 'To').includes(email)) {
      return (
        <div className={styles.currentThreadContainer}>
          <div className={styles.messageViewContainer}>
            <p className={styles.messagePreLine}>{emailContent}</p>
          </div>
          <Button onClick={() => console.log('Implement Reply')}>
            <i className="fas fa-reply"></i>
            <p className={styles.messageButtonText}> Draft Reply </p>
          </Button>
        </div>
      );
    }
  };

  return (
    <Box className={styles.appContainer}>
      <NavbarApp />
      {renderTopContainer()}
      <Box className={styles.bodyContainer}>
        {renderThreadHistory()}
        {renderCurrentThread()}
      </Box>
    </Box>
  );
};

export default ThreadPage;
