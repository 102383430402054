import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import BugReport from './components/BugReport/BugReport.js';
import { NotificationsProvider } from './core/notifications.js';
import store from './core/store.js';
import './index.css';
import './styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline>
      <Router>
        <Provider store={store}>
          <NotificationsProvider>
            <App />
            <BugReport />
          </NotificationsProvider>
        </Provider>
      </Router>
    </CssBaseline>
  </React.StrictMode>
);
