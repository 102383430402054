import { Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GmailLogo from '../../../assets/GmailLogo.webp';
import OutlookLogo from '../../../assets/OutlookLogo.webp';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import AccountSideBar from '../../../components/SideBar/AccountSideBar.js';
import config from '../../../core/config.js';
import { fetchUserBackendData } from '../../../redux/thunks/UserThunks.js';
import styles from './AccountPage.module.css';

const AccountPage = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '', photoURL = '', displayName = '', email = '' } = userData;

  const linkGmailEmailAccount = async () => {
    try {
      window.location.href = `${config.backendHost}/start-gmail-oauth?uid=${uid}`;
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(fetchUserBackendData(uid));
  };

  const linkOutlookEmailAccount = async () => {
    try {
      window.location.href = `${config.backendHost}/start-outlook-oauth?uid=${uid}`;
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(fetchUserBackendData(uid));
  };

  return (
    <div className="default-sidebar-page-container">
      <NavbarApp />
      <div className="default-sidebar-nav-container">
        <AccountSideBar />
      </div>
      <Box className="default-sidebar-main-container">
        <h1>Your account</h1>
        <div className={styles.accountInfoContainer}>
          {photoURL && (
            <img
              src={photoURL}
              alt="Profile"
              className={styles.accountProfilePic}
            />
          )}
          {displayName && <h2 style={{ margin: 5 }}>{displayName}</h2>}
        </div>
        <div className={styles.accountSection}>
          <Box>
            <h2>Email address: {email}</h2>
            <h2>
              Gmail account: &nbsp;
              <Button
                onClick={linkGmailEmailAccount}
                className="greyButton"
                startIcon={
                  <img
                    src={GmailLogo}
                    alt="GmailLogo"
                    className={styles.logoImagePic}
                  />
                }
              >
                Link Gmail Email Account
              </Button>
            </h2>
            <h2>
              Outlook account: &nbsp;
              <Button
                onClick={linkOutlookEmailAccount}
                className="greyButton"
                startIcon={
                  <img
                    src={OutlookLogo}
                    alt="OutlookLogo"
                    className={styles.logoImagePic}
                  />
                }
              >
                Link Outlook Email Account
              </Button>
            </h2>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default AccountPage;
