import { createAsyncThunk } from '@reduxjs/toolkit';

import { postMessage } from '../../core/server.js';

export const fetchThreads = createAsyncThunk(
  'threads/fetchThreads',
  async (uid, thunkAPI) => {
    try {
      const response = await postMessage('fetch_and_sync_managed_threads', {
        uid,
      });
      return response.body;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchThreadWithThreadId = createAsyncThunk(
  'threads/fetchThreadWithThreadId',
  async ({ uid, threadId }, thunkAPI) => {
    try {
      const response = await postMessage(
        'fetch_and_sync_thread_with_thread_id',
        {
          uid,
          threadId,
        }
      );
      return response.body;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
