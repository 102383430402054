export const defaultTemplates = [
  {
    templateName: 'Default Hiring',
    subject:
      'Exciting Opportunity at {{ Company Name }} for {{ Recipient Name }}',
    body:
      'Dear {{ Recipient FirstName }},\n\n' +
      "I hope this email finds you well. I am {{ Your Name }}, reaching out from {{ Company Name }}. As we continue to grow and innovate, we are always on the lookout for talented individuals who can contribute to our team's success.\n\n" +
      "Upon reviewing your profile, I believe your skills and experience could be an excellent fit for a role we're currently looking to fill. At our company, we value [mention specific skills/qualities relevant to the role and the company].\n\n" +
      "I would love the opportunity to discuss how your expertise and aspirations align with the exciting developments at our company. Please let me know if you're interested in learning more about this opportunity, and we can arrange a convenient time for a call.\n\n" +
      'Looking forward to the possibility of working together.\n\n' +
      'Best regards,\n' +
      '{{ Your Name }}',
  },
  {
    templateName: 'Default Networking',
    subject: 'Connecting about Restructuring at {{ Company Name }}',
    body:
      'Hello {{ Recipient FirstName }},\n\n' +
      "My name is {{ Your Name }} and I'm a student at Berkeley studying public policy. I see that you are on the restructuring team at {{ Company Name }} and restructuring is a field of finance I'm committed to recruiting for a career in. I understand you might be receiving many such requests, but I was hoping to have a brief conversation with you. A quick 10-minute call to discuss your career and insights about the {{ Company Name }} restructuring team would be incredibly valuable to me.\n\n" +
      "Let me know if you're available for a brief chat – my schedule is quite flexible. I've also attached my resume for your reference.\n\n" +
      'Thank you for considering my request, and I hope to hear from you soon.\n\n' +
      'Best regards,\n' +
      '{{ Your Name }}',
  },
  {
    templateName: 'Default Recruiting',
    subject: 'Application for Opportunities at {{ Company Name }}',
    body:
      'Dear {{ Recipient FirstName }} at {{ Company Name }},\n\n' +
      'I am writing to express my interest in any current or future opportunities at {{ Company Name }}. My name is {{ Your Name }}, and I have a strong background in [mention your relevant field or expertise].\n\n' +
      'I am particularly impressed by the industry-leading standards and innovative approaches {{ Company Name }} is known for and I am excited about the idea of contributing to your work. My experience in [relevant experience or skill] aligns well with the type of talent you seek.\n\n' +
      'I have attached my resume for your review and would welcome the chance to discuss how my skills and experience align with the goals of {{ Company Name }}. Thank you for considering my application. I look forward to the opportunity to speak with you.\n\n' +
      'Best regards,\n' +
      '{{ Your Name }}',
  },
];
