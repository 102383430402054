import { fetchTemplates } from './TemplatesThunks';
import { fetchUserBackendData } from './UserThunks';

export const fetchAllUserData = (userId) => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchTemplates(userId)),
      dispatch(fetchUserBackendData(userId)),
    ]);
  } catch (error) {
    throw error;
  }
};
