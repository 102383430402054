import { Box } from '@mui/material';
import React from 'react';

import NavbarApp from '../../../components/Navbar/NavbarApp.js';

const PaymentPage = () => {
  return (
    <Box className="default-container">
      <NavbarApp />
      <h2>TODO: Add Payment Support & Backend Integration.</h2>
    </Box>
  );
};

export default PaymentPage;
