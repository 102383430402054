import { createSlice } from '@reduxjs/toolkit';

import { fetchUserBackendData, fetchUserUsage } from '../thunks/UserThunks';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    linked: null,
    tier: null,
    usage: null,
    limits: null,
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    unsetUser: (state) => {
      state.data = null;
      state.labelId = null;
      state.linked = null;
      state.tier = null;
      state.usage = null;
      state.limits = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBackendData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserBackendData.fulfilled, (state, action) => {
        state.loading = false;
        state.labelId = action.payload.labelId;
        state.linked = action.payload.linked;
        state.tier = action.payload.tier;
        state.usage = action.payload.usage;
        state.limits = action.payload.limits;
      })
      .addCase(fetchUserBackendData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserUsage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserUsage.fulfilled, (state, action) => {
        state.usage = action.payload;
      })
      .addCase(fetchUserUsage.rejected, (state, action) => {
        state.loading - false;
        state.error = action.payload;
      });
  },
});

export const { setUser, unsetUser } = userSlice.actions;

export default userSlice.reducer;
