import dayjs from 'dayjs';

export const decodeBase64 = (base64Str) => {
  const base64 = base64Str.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
};

export const removeEmptyListsFromObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

export const trimStringToNumChars = (subject, numChars = 32) => {
  if (subject.length > numChars) {
    return subject.substring(0, numChars - 3) + '...';
  }
  return subject;
};

export const isPastDate = (date) => {
  return dayjs(date).isBefore(dayjs(), 'day');
};

export const htmlToPlainText = (html) => {
  let text = html.replace(/<br\s*[\/]?>/gi, '\n');
  text = text.replace(/<\/(p|div|h[1-6]|ul|ol|li)>\s*/gi, '\n');
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = text;
  return tempDiv.textContent || tempDiv.innerText || '';
};
