import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Box className="default-container">
      <h2>Succesfully Authorizated Email.</h2>
      <Button onClick={() => navigate('/a/account')} className="default-button">
        Return Home
      </Button>
    </Box>
  );
};

export default SuccessPage;
