export const landingQuestions = [
  {
    question: 'How can Fireoff help me send emails faster?',
    answer:
      'Fireoff streamlines the process of researching who to contact, finding email addresses, tailoring each email to your recipient, and sending off your emails. By integrating these tasks into a single, efficient workflow, Fireoff enables users to email hundreds of their ideal contacts with personalized messages in less 3 minutes.',
  },
  {
    question:
      'How does Fireoff ensure that personalized messages are tailored to each recipient?',
    answer:
      'Fireoff uses advanced algorithms and natural language processing (NLP) to create personalized messages for each recipient based on their profile and preferences. Users can input their draft message and allow Fireoff to enhance it with customized messages accordingly. Users can always review and approve the messages before sending.',
  },
  {
    question:
      'Who should I contact if I encounter bugs or have ideas for new features?',
    answer:
      'Please direct any bug reports or feature requests to support@fireoff.ai. Our team will promptly address any issues you encounter and carefully consider any suggestions for improving our platform. Your feedback is invaluable to us as we strive to provide the best experience for our users.',
  },
];
