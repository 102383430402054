// Import Theme
import { ThemeProvider } from '@mui/material';
import React from 'react';
import { clarity } from 'react-microsoft-clarity';
import { Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import PrivateRoute from './components/Private/PrivateRoute.js';
import theme from './constants/muiTheme.js';
import config from './core/config.js';
// Import Auth Routes
import SignInPage from './pages/AuthFlow/SigninPage/SigninPage.js';
import WaitlistPage from './pages/AuthFlow/WaitlistPage/WaitlistPage.js';
// Import Dash Routes
import DashboardPage from './pages/DashboardFlow/DashboardPage/DashboardPage.js';
import ThreadPage from './pages/DashboardFlow/ThreadPage/ThreadPage.js';
// Import Public Routes
import FallbackPage from './pages/PublicFlow/FallbackPage/FallbackPage.js';
import LandingPage from './pages/PublicFlow/LandingPage/LandingPage.js';
import PricingPage from './pages/PublicFlow/PricingPage/PricingPage.js';
// Import Query Routes
import QueryPage from './pages/QueryFlow/QueryPage/QueryPage.js';
import ReviewPage from './pages/QueryFlow/ReviewPage/ReviewPage.js';
import SelectPage from './pages/QueryFlow/SelectPage/SelectPage.js';
import TemplatePage from './pages/QueryFlow/TemplatePage/TemplatePage.js';
// Import User Routes
import AccountPage from './pages/UserFlow/AccountPage/AccountPage.js';
import OutcomePage from './pages/UserFlow/OutcomePage/OutcomePage.js';
import PaymentPage from './pages/UserFlow/PaymentPage/PaymentPage.js';
import SuccessPage from './pages/UserFlow/SuccessPage/SuccessPage.js';
import UserPlanPage from './pages/UserFlow/UserPlanPage/UserPlanPage.js';

const App = () => {
  if (!window.location.host.includes('localhost')) {
    clarity.init(config.projectToken);
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* Public Routes*/}
        <Route path="/" element={<LandingPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        {/* Auth Flow Routes */}
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        {/* Protected Routes */}
        <Route path="/app" element={<Navigate replace to="/a/query" />} />
        <Route
          path="/a/success"
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/query"
          element={
            <PrivateRoute>
              <QueryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/select"
          element={
            <PrivateRoute>
              <SelectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/template"
          element={
            <PrivateRoute>
              <TemplatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/review"
          element={
            <PrivateRoute>
              <ReviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/dashboard/thread/:threadId"
          element={
            <PrivateRoute>
              <ThreadPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/account"
          element={
            <PrivateRoute>
              <AccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/plan"
          element={
            <PrivateRoute>
              <UserPlanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/payment"
          element={
            <PrivateRoute>
              <PaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/a/payment_outcome"
          element={
            <PrivateRoute>
              <OutcomePage />
            </PrivateRoute>
          }
        />
        {/* Fallback Route */}
        <Route path="*" element={<FallbackPage />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
