import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff802c',
          '&:hover': {
            backgroundColor: 'rgba(255, 128, 44, 0.8)',
          },
          textTransform: 'none',
          fontSize: '1rem',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 'bold',
          color: 'white',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff802c',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: '55px',
        },
      },
    },
  },
});

export default theme;
