import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import styles from './Notifications.module.css';

const Notifications = ({
  notifications,
  removeNotification,
  hideAfter = 3000,
}) => {
  useEffect(() => {
    if (notifications.length === 0) {
      return;
    }
    const latestNotification = notifications[0];
    const timer = setTimeout(() => {
      removeNotification(latestNotification.id);
    }, hideAfter);

    return () => clearTimeout(timer);
  }, [notifications, removeNotification, hideAfter]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Box>
      {notifications.map((notification, index) => (
        <Box
          className={styles.notification}
          key={notification.id}
          sx={{
            top: `${index * 75 + 100}px`,
            backgroundColor:
              notification.type === 'success'
                ? '#4CAF50'
                : notification.type === 'info'
                  ? '#e8d637'
                  : '#e96540',
          }}
        >
          {notification.message}
          <CloseIcon
            onClick={() => removeNotification(notification.id)}
            className={styles.closeIcon}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Notifications;
