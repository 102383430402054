import React from 'react';
import { useNavigate } from 'react-router-dom';

import FireOffLogo from '../../assets/FireOffLogo.webp';
import FireOffTextLogo from '../../assets/FireOffTextLogo.webp';
import { firebase } from '../../core/firebase';
import styles from './Navbar.module.css';

const NavbarStatic = ({ hamActive, setHamActive }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    setHamActive(!hamActive);
  };

  const handleSignInClick = () => {
    if (firebase.currentUser) {
      navigate('/a/query');
    } else {
      navigate('/signin');
    }
  };

  return (
    <nav className={`${styles.navbarWrapper} center`}>
      <div className={`${styles.navbarInner} center`}>
        <button
          className={`${styles.hamburger} ${hamActive && styles.active}`}
          onClick={handleClick}
        >
          <span className={styles.hamburgerLines}></span>
        </button>
        <div className={`${styles.navLeft}`}>
          <img src={FireOffLogo} alt="Logo" className={styles.logo} />
          <img
            src={FireOffTextLogo}
            alt="Word Logo"
            className={styles.wordLogo}
          />
        </div>

        <div className={`${styles.navRight} center`}>
          <div className={styles.navLinksWrapper}>
            <div className={styles.verticalLine}> </div>
            <a href="#Product" className={`${styles.nav} center`}>
              Product
            </a>
            <a href="#Pricing" className={`${styles.nav} center`}>
              Pricing
            </a>
            <a
              href="mailto:support@fireoff.ai"
              className={`${styles.nav} center`}
            >
              Contact Us
            </a>
          </div>
          <div>
            <button
              className={styles.login}
              onClick={() => navigate('/waitlist')}
            >
              Join Our Waitlist
            </button>
            <button className={styles.signup} onClick={handleSignInClick}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarStatic;
