import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip, TextField, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';

import styles from './Filters.module.css';

const MultipleInputFilter = ({
  filterDisplayName,
  filterName,
  sync,
  init,
  collapse,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  const [filterValue, setFilterValue] = useState(init[filterName] || []);
  const [excludeFilterValue, setExcludeFilterValue] = useState(
    init[`exclude_${filterName}`] || []
  );
  const [inputValue, setInputValue] = useState('');
  const [filterOpen, setFilterOpen] = useState(
    restrictOneOpenFilter ? currOpenFilter === filterName : !collapse
  );

  useEffect(() => {
    if (restrictOneOpenFilter) {
      setFilterOpen(currOpenFilter === filterName);
    }
  }, [currOpenFilter, filterName, restrictOneOpenFilter]);

  const handleFilterEnter = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      const newFilterValue = [...filterValue, event.target.value];
      setFilterValue(newFilterValue);
      setInputValue('');
      sync((prev) => ({
        ...prev,
        [filterName]: newFilterValue,
        [`exclude_${filterName}`]: excludeFilterValue,
      }));
    }
  };

  const handleFilterRemove = (value, isExcluded = false) => {
    if (isExcluded) {
      const newExcludeFilterValue = excludeFilterValue.filter(
        (item) => item !== value
      );
      setExcludeFilterValue(newExcludeFilterValue);
      sync((prev) => ({
        ...prev,
        [filterName]: filterValue,
        [`exclude_${filterName}`]: newExcludeFilterValue,
      }));
    } else {
      const newFilterValue = filterValue.filter((item) => item !== value);
      setFilterValue(newFilterValue);
      sync((prev) => ({
        ...prev,
        [filterName]: newFilterValue,
        [`exclude_${filterName}`]: excludeFilterValue,
      }));
    }
  };

  const handleInvertFilter = (value) => {
    let newFilterValue, newExcludeFilterValue;
    if (excludeFilterValue.includes(value)) {
      newExcludeFilterValue = excludeFilterValue.filter((v) => v !== value);
      newFilterValue = [...filterValue, value];
    } else {
      newFilterValue = filterValue.filter((v) => v !== value);
      newExcludeFilterValue = [...excludeFilterValue, value];
    }
    setFilterValue(newFilterValue);
    setExcludeFilterValue(newExcludeFilterValue);
    sync((prev) => ({
      ...prev,
      [filterName]: newFilterValue,
      [`exclude_${filterName}`]: newExcludeFilterValue,
    }));
  };

  const toggleFilterOpen = () => {
    if (restrictOneOpenFilter) {
      if (currOpenFilter === filterName) {
        setCurrOpenFilter('');
      } else {
        setCurrOpenFilter(filterName);
      }
    } else {
      setFilterOpen(!filterOpen);
    }
  };

  return (
    <Box className={styles.filterContainer}>
      <Box className={styles.filterInputContainer}>
        <Typography gutterBottom>{filterDisplayName}</Typography>
        {collapse &&
          (filterOpen ? (
            <ExpandMoreIcon onClick={toggleFilterOpen} />
          ) : (
            <ChevronRightIcon onClick={toggleFilterOpen} />
          ))}
      </Box>
      {filterOpen ? (
        <TextField
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleFilterEnter}
          variant="outlined"
          className={styles.filterSelectContainer}
        />
      ) : (
        <div />
      )}
      <Box className={styles.filterChipContainer}>
        {filterValue.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => handleFilterRemove(value)}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: green[500], margin: 2 }}
            onClick={() => handleInvertFilter(value)}
          />
        ))}
        {excludeFilterValue.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => handleFilterRemove(value, true)}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: red[500], margin: 2 }}
            onClick={() => handleInvertFilter(value)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MultipleInputFilter;
