import { createSlice } from '@reduxjs/toolkit';

import { fetchTemplates } from '../thunks/TemplatesThunks.js';

export const templateSlice = createSlice({
  name: 'templates',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    unsetTemplates: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { unsetTemplates } = templateSlice.actions;

export default templateSlice.reducer;
