import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import styles from './Filters.module.css';

const CheckInputFilter = ({
  filterDisplayName,
  filterName,
  filterOptions,
  sync,
  init,
  collapse,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  const [filterValue, setFilterValue] = useState(init[filterName] || []);
  const [excludeFilterValue, setExcludeFilterValue] = useState(
    init[`exclude_${filterName}`] || []
  );
  const [openSubcategories, setOpenSubcategories] = useState({});
  const [filterOpen, setFilterOpen] = useState(
    restrictOneOpenFilter ? currOpenFilter === filterName : !collapse
  );

  useEffect(() => {
    if (restrictOneOpenFilter) {
      setFilterOpen(currOpenFilter === filterName);
    }
  }, [currOpenFilter, filterName, restrictOneOpenFilter]);

  const toggleSubcategoryVisibility = (name) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleSelectChange = (option, isCategory = false) => {
    let newValue;
    if (isCategory) {
      const subcategories =
        filterOptions.find((o) => o.name === option)?.subcategories || [];
      if (isOptionSelected(option)) {
        newValue = filterValue.filter(
          (value) =>
            value !== option &&
            !subcategories.includes(value.replace(`${option}.`, ''))
        );
      } else {
        newValue = [...new Set([...filterValue, option, ...subcategories])];
      }
    } else {
      newValue = filterValue.includes(option)
        ? filterValue.filter((value) => value !== option)
        : [...filterValue, option];
    }
    setFilterValue(newValue);
    sync((prev) => ({ ...prev, [filterName]: newValue }));
  };

  const isOptionSelected = (optionName) => {
    if (
      filterOptions.some(
        (option) => option.name === optionName && typeof option === 'object'
      )
    ) {
      const subcategories =
        filterOptions.find((option) => option.name === optionName)
          ?.subcategories || [];
      return subcategories.every((sub) => filterValue.includes(sub));
    } else {
      return filterValue.includes(optionName);
    }
  };

  const toggleFilterOpen = () => {
    if (restrictOneOpenFilter) {
      if (currOpenFilter === filterName) {
        setCurrOpenFilter('');
      } else {
        setCurrOpenFilter(filterName);
      }
    } else {
      setFilterOpen(!filterOpen);
    }
  };

  const renderOptions = (options) => {
    return options.map((option) => {
      if (typeof option === 'object') {
        return [
          <MenuItem key={option.name} value={option.name}>
            <Checkbox
              checked={isOptionSelected(option.name)}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectChange(option.name, true);
              }}
            />
            <ListItemText primary={option.name} />
            {openSubcategories[option.name] ? (
              <ExpandMoreIcon
                onClick={() => toggleSubcategoryVisibility(option.name)}
              />
            ) : (
              <ChevronRightIcon
                onClick={() => toggleSubcategoryVisibility(option.name)}
              />
            )}
          </MenuItem>,
          ...option.subcategories.map((sub) =>
            openSubcategories[option.name] ? (
              <MenuItem
                key={`${option.name}.${sub}`}
                value={sub}
                onClick={() => handleSelectChange(sub)}
                style={{ paddingLeft: '30px' }}
              >
                <Checkbox checked={filterValue.includes(sub)} />
                <ListItemText primary={sub} />
              </MenuItem>
            ) : null
          ),
        ];
      } else {
        return (
          <MenuItem
            key={option}
            value={option}
            onClick={() => handleSelectChange(option)}
          >
            <Checkbox checked={isOptionSelected(option)} />
            <ListItemText primary={option} />
          </MenuItem>
        );
      }
    });
  };

  return (
    <Box className={styles.filterContainer}>
      <Box className={styles.filterInputContainer}>
        <Typography gutterBottom>{filterDisplayName}</Typography>
        {collapse &&
          (filterOpen ? (
            <ExpandMoreIcon onClick={toggleFilterOpen} />
          ) : (
            <ChevronRightIcon onClick={toggleFilterOpen} />
          ))}
      </Box>
      {filterOpen ? (
        <Select
          multiple
          value={filterValue}
          onChange={(e) => {}}
          input={<OutlinedInput label={filterDisplayName} />}
          renderValue={(selected) => `${selected.length} Item(s) Selected.`}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 180,
              },
            },
          }}
          className={styles.filterSelectContainer}
        >
          {renderOptions(filterOptions)}
        </Select>
      ) : (
        <div />
      )}
      <Box className={styles.filterChipContainer}>
        {filterValue.map((value) => (
          <Chip
            key={value}
            label={value}
            color="primary"
            // We have to use style here; className is overrided by MUI
            style={{ backgroundColor: 'grey', margin: 2 }}
            onDelete={() => handleSelectChange(value)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CheckInputFilter;
