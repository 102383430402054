import React from 'react';

import styles from './Breadcrumb.module.css';

const Breadcrumb = ({ steps, activeStep }) => {
  return (
    <div className={styles.breadcrumbContainer}>
      {steps.map((step, index) => (
        <div key={index} className={styles.stepWrapper}>
          <div className={styles.stepContainer}>
            <div
              className={`${styles.circle} ${
                index === activeStep ? styles.activeCircle : ''
              }`}
            >
              <div className={styles.stepName}>{step}</div>
            </div>
          </div>
          {index < steps.length - 1 && <div className={styles.line}></div>}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
