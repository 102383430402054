import { Box, Button, Checkbox } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import DefaultProfilePic from '../../../assets/DefaultProfilePic.webp';
import LinkedinIcon from '../../../assets/LinkedinIcon.webp';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb.js';
import FilterGroup from '../../../components/Filters/FilterGroup.js';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator.js';
import NavbarApp from '../../../components/Navbar/NavbarApp.js';
import { supportedFilters } from '../../../constants/supportedFilters.js';
import { removeEmptyListsFromObj } from '../../../core/functions.js';
import { useNotifications } from '../../../core/notifications.js';
import { postMessage } from '../../../core/server.js';
import styles from './SelectPage.module.css';

const numAdditionalToLoad = 25;

const SelectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [pagination, setPagination] = useState(location.state?.pagination);
  const [paginationNext, setPaginationNext] = useState(pagination?.next);
  const [profiles, setProfiles] = useState(location.state?.profiles);
  const [currentFilters, setCurrentFilters] = useState(location.state?.filters);
  const [currentSearchFilters, setCurrentSearchFilters] = useState(
    location.state?.filters
  );
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [showUserSelections, setShowUserSelections] = useState(false);
  const [currSelectionList, setCurrSelectionList] = useState([]);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startSelect, setStartSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const scrollContainerRef = useRef(null);
  const userData = useSelector((state) => state.user.data) || {};
  const { uid = '' } = userData;

  useEffect(() => {
    const container = scrollContainerRef.current;
    const handleScroll = () => {
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 0.5;
      if (isAtBottom) {
        onScrollToBottom();
      }
    };
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleSelectProfile = (profile) => {
    if (selectedProfiles.includes(profile)) {
      setSelectedProfiles(selectedProfiles.filter((p) => p !== profile));
    } else {
      setSelectedProfiles([...selectedProfiles, profile]);
    }
  };

  const toggleShowUserSelections = () => {
    setCurrSelectionList(selectedProfiles);
    setShowUserSelections(!showUserSelections);
  };

  const handleMouseDown = (profile) => (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartSelect(!selectedProfiles.includes(profile));
    toggleSelectProfile(profile);
  };

  const handleMouseOver = (profile) => () => {
    if (isDragging) {
      if (startSelect) {
        if (!selectedProfiles.includes(profile)) {
          toggleSelectProfile(profile);
        }
      } else {
        if (selectedProfiles.includes(profile)) {
          toggleSelectProfile(profile);
        }
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const onScrollToBottom = () => {
    // TODO: Implement this function to load more profiles when the user scrolls to the bottom
    // if (!isExecuting && !isLoading && profiles?.length <= pagination.total) {
    //   loadMoreProfiles();
    // }
  };

  const executeQuery = async () => {
    setCurrentFilters(removeEmptyListsFromObj(currentFilters));
    if (Object.keys(currentFilters).length === 0) {
      addNotification({
        message: 'Please enter a query or select filters.',
        type: 'error',
      });
      return;
    }
    setIsExecuting(true);
    addNotification({
      message: 'Running a new search with the provided filters...',
      type: 'info',
    });
    const executePayload = { uid, filters: currentFilters };
    const response = await postMessage('execute_query', executePayload);
    if (response.status == 200) {
      addNotification({
        message: 'Successfully executed new query and found more profiles!',
        type: 'success',
      });
      setCurrentSearchFilters(currentFilters);
      setPagination(response.body.pagination);
      setPaginationNext(response.body.pagination.next);
      setProfiles(response.body.profiles);
    } else {
      if (response.body.errorType == 'noneFoundError') {
        addNotification({
          message: 'No profiles found. Try broadening your search.',
          type: 'error',
        });
      } else if (response.body.errorType == 'apiError') {
        addNotification({
          message: 'Server error executing query. Please try again later.',
          type: 'error',
        });
      } else {
        addNotification({
          message:
            'Error executing query with Unknown Error. Please try again.',
          type: 'error',
        });
      }
    }
    setIsExecuting(false);
  };

  const loadMoreProfiles = async () => {
    setIsLoading(true);
    addNotification({
      message: 'Loading more profiles with current search filters...',
      type: 'info',
    });
    const executePayload = {
      uid,
      filters: currentSearchFilters,
      start: paginationNext,
      page_size: numAdditionalToLoad,
    };
    const response = await postMessage('execute_query', executePayload);
    if (response.status == 200) {
      addNotification({
        message: 'Successfully loaded more profiles!',
        type: 'success',
      });
      setIsLoading(false);
      setPaginationNext(response.body.pagination.next);
      setProfiles([...profiles, ...response.body.profiles]);
    } else {
      addNotification({
        message: 'Error loading more profiles. Please try again.',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const renderTopContainer = () => {
    return (
      <div className={styles.topContainer}>
        <div className={styles.topBreadcrumbContainer}>
          <Breadcrumb
            steps={['Query', 'Select', 'Template', 'Review']}
            activeStep={1}
          />
        </div>
        <div className={styles.topButtonContainer}>
          <div className={styles.topButton}>
            <Button
              variant="contained"
              onClick={toggleShowUserSelections}
              className="default-button"
            >
              {showUserSelections
                ? 'Show Search Results'
                : `Show ${selectedProfiles.length} Selected Profile(s)`}
            </Button>
          </div>
          <div className={styles.topButton}>
            <Button
              variant="contained"
              disabled={selectedProfiles?.length === 0}
              onClick={() =>
                navigate('/a/template', { state: { selectedProfiles } })
              }
              className="default-button"
            >
              Contact Selected Profiles
            </Button>
          </div>
        </div>
        <div className={styles.topInfoTextContainer}>
          <h2>Select or drag over the profiles you'd like to reach</h2>
          <i
            className={`fa fa-arrow-down ${styles.bounce}`}
            aria-hidden="true"
          ></i>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <Box className={styles.filterContainer}>
        <div className={styles.filterTitleContainer}>Filters</div>
        {supportedFilters.map((filterGroup) => (
          <FilterGroup
            key={filterGroup.categoryName}
            filterGroup={filterGroup}
            setFilters={setCurrentFilters}
            currFilters={currentFilters}
            restrictOneOpenFilter={false}
          />
        ))}
        <Button
          variant="contained"
          disabled={
            currentFilters.length === 0 ||
            currentFilters === currentSearchFilters
          }
          onClick={executeQuery}
          className="default-button"
        >
          Execute Query
        </Button>
      </Box>
    );
  };

  const renderUserSelections = () => {
    return (
      <Box className={styles.contactContainer}>
        {currSelectionList.map((profile, index) => (
          <div
            key={index}
            className={styles.contactItem}
            onMouseOver={handleMouseOver(profile)}
            onMouseDown={handleMouseDown(profile)}
            onMouseUp={handleMouseUp}
          >
            <div key={index} className={styles.contactItem}>
              <div className={styles.contactLeft}>
                <div className={styles.contactInfo}>
                  <img
                    src={profile.profile_pic || DefaultProfilePic}
                    alt={`${profile.name}'s profile`}
                    onError={(e) => {
                      if (!e.target.src.includes(DefaultProfilePic)) {
                        e.target.src = DefaultProfilePic;
                      }
                    }}
                    onClick={() => window.open(profile.linkedin_url, '_blank')}
                    className={styles.contactProfilePic}
                  />
                  <img
                    src={LinkedinIcon}
                    alt={`${profile.name}'s LinkedIn`}
                    onClick={() => window.open(profile.linkedin_url, '_blank')}
                    className={styles.contactLinkedInIcon}
                  />
                </div>
                <div className="contact-info">
                  <h2>{profile.name}</h2>
                  <p>{profile.current_title}</p>
                </div>
              </div>
              <div className={styles.contactRight}>
                <div className={styles.contactDetails}>
                  <p style={{ fontWeight: 500 }}>{profile.current_employer}</p>
                  <p>{profile.location}</p>
                </div>
                <Checkbox
                  checked={selectedProfiles.includes(profile)}
                  sx={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    transform: 'scale(1.5)',
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </Box>
    );
  };

  const renderSearchResults = () => {
    return (
      <Box className={styles.contactContainer} ref={scrollContainerRef}>
        {!isExecuting &&
          profiles.map((profile, index) => (
            <div
              key={index}
              className={styles.contactItem}
              onMouseOver={handleMouseOver(profile)}
              onMouseDown={handleMouseDown(profile)}
              onMouseUp={handleMouseUp}
            >
              <div key={index} className={styles.contactItem}>
                <div className={styles.contactLeft}>
                  <div className={styles.contactInfo}>
                    <img
                      src={profile.profile_pic || DefaultProfilePic}
                      alt={`${profile.name}'s profile`}
                      onError={(e) => {
                        if (!e.target.src.includes(DefaultProfilePic)) {
                          e.target.src = DefaultProfilePic;
                        }
                      }}
                      onClick={() =>
                        window.open(profile.linkedin_url, '_blank')
                      }
                      className={styles.contactProfilePic}
                    />
                    <img
                      src={LinkedinIcon}
                      alt={`${profile.name}'s LinkedIn`}
                      onClick={() =>
                        window.open(profile.linkedin_url, '_blank')
                      }
                      className={styles.contactLinkedInIcon}
                    />
                  </div>
                  <div className="contact-info">
                    <h2>{profile.name}</h2>
                    <p>{profile.current_title}</p>
                  </div>
                </div>
                <div className={styles.contactRight}>
                  <div className={styles.contactDetails}>
                    <p style={{ fontWeight: 500 }}>
                      {profile.current_employer}
                    </p>
                    <p>{profile.location}</p>
                  </div>
                  <Checkbox
                    checked={selectedProfiles.includes(profile)}
                    sx={{
                      marginRight: '10px',
                      marginLeft: '10px',
                      transform: 'scale(1.5)',
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        {isExecuting && <LoadingIndicator />}
      </Box>
    );
  };

  const renderBottomContainer = () => {
    return (
      <Box>
        <h3 className={styles.bottomTextContainer}>
          Displaying {profiles?.length} of {pagination.total} total profiles
        </h3>
        <Button
          variant="contained"
          onClick={loadMoreProfiles}
          disabled={isLoading || profiles?.length === pagination.total}
          className="default-button"
        >
          Load More Profiles
        </Button>
      </Box>
    );
  };

  return (
    <Box className={styles.appContainer}>
      <NavbarApp />
      {renderTopContainer()}
      <Box className={styles.bodyContainer}>
        {renderFilters()}
        {showUserSelections ? renderUserSelections() : renderSearchResults()}
      </Box>
      {renderBottomContainer()}
    </Box>
  );
};

export default SelectPage;
