import React from 'react';

import styles from './BugReport.module.css';

const BugReport = () => {
  const googleFormLink = 'https://forms.gle/otwJakdGFYmDTpoK8';

  const openForm = () => {
    window.open(googleFormLink, '_blank');
  };

  return (
    <button onClick={openForm} className={styles.bugReportButton}>
      Report Bug
    </button>
  );
};

export default BugReport;
