import React from 'react';

import styles from './Filters.module.css';
import WrappedFilter from './WrappedFilter';

const FilterGroup = ({
  filterGroup,
  setFilters,
  currFilters,
  restrictOneOpenFilter,
  currOpenFilter,
  setCurrOpenFilter,
}) => {
  return (
    <div key={filterGroup.categoryName} className={styles.filterGroupContainer}>
      <p className={styles.filterGroupName}>{filterGroup.categoryName}</p>
      {filterGroup.filters.map((filterConfig) => (
        <WrappedFilter
          key={filterConfig.filterName}
          filterConfig={filterConfig}
          sync={setFilters}
          init={currFilters}
          restrictOneOpenFilter={restrictOneOpenFilter}
          currOpenFilter={currOpenFilter}
          setCurrOpenFilter={setCurrOpenFilter}
          collapse={true}
        />
      ))}
    </div>
  );
};

export default FilterGroup;
