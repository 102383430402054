import Button from '@mui/material/Button';
import React from 'react';

import styles from './FileUploadButton.module.css';

function FileUploadButton({ uploadRef, onChange }) {
  return (
    <>
      <input
        accept="*/*"
        id="raised-button-file"
        multiple
        type="file"
        onChange={onChange}
        ref={uploadRef}
        className={styles.fileUploadInput}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          component="span"
          sx={{
            boxShadow: 'none',
            '&:active': { boxShadow: 'none' },
            '&:hover': { boxShadow: 'none' },
          }}
        >
          Upload
        </Button>
      </label>
    </>
  );
}

export default FileUploadButton;
