import React, { useEffect, useRef, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { firebase } from '../../core/firebase.js';
import { unsetTemplates } from '../../redux/reducers/TemplatesReducer.js';
import { unsetThreads } from '../../redux/reducers/ThreadsReducer.js';
import { setUser, unsetUser } from '../../redux/reducers/UserReducer.js';
import { fetchAllUserData } from '../../redux/thunks/Thunks.js';
import '../../styles.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.js';

const PrivateRoute = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingOrUnloading, setLoadingOrUnloading] = useState(true);
  const fetchedUserData = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingOrUnloading(true);
    const checkAuth = firebase.auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        if (!window.location.host.includes('localhost') && clarity.hasStarted) {
          clarity.identify(user.uid);
        }
        dispatch(
          setUser({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          })
        );

        if (!fetchedUserData.current) {
          try {
            fetchedUserData.current = true;
            await dispatch(fetchAllUserData(user.uid));
          } catch (error) {
            fetchedUserData.current = false;
            console.error('Failed to fetch user data:', error);
          }
        }
        setLoadingOrUnloading(false);
      } else {
        setCurrentUser(null);
        dispatch(unsetUser());
        dispatch(unsetThreads());
        dispatch(unsetTemplates());
        fetchedUserData.current = false;
      }
      setLoadingOrUnloading(false);
    });

    return () => checkAuth();
  }, [dispatch]);

  if (loadingOrUnloading) {
    return <LoadingIndicator />;
  }

  return currentUser ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
