import { landingQuestions } from '../../constants/landingQuestions';
import Accordion from '../Accordion/Accordion';
import styles from './Questions.module.css';

const Questions = () => {
  return (
    <div className={`${styles.QuestionWrapper} center`}>
      <div className={`${styles.QuestionsWrapperInner} center`}>
        <div className={styles.QuestionsHeading}>
          <p>Common Questions</p>
        </div>
        <div className={`${styles.QuestionsList} center`}>
          {landingQuestions.map(({ question, answer }, index) => {
            return (
              <Accordion
                key={index}
                isUpper={true}
                title={question}
                content={answer}
                iconOpen="plus"
                iconClose="minus"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Questions;
